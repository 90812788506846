import axios from 'axios';
import { NavManagerContextProvider, transformNavMenusToContexts, ContextConfig } from '@nab/x-spa-react';
import { Menu } from '@nab/x-types';
import { getBearerToken } from '../session';

export class HiveNavManagerContextProvider extends NavManagerContextProvider {
  private baseNavManagerUrl: string;

  constructor(baseUrl: string) {
    super(baseUrl);
    this.baseNavManagerUrl = baseUrl;
  }

  public async getContext(contextId): Promise<ContextConfig> {
    let result;
    const token = getBearerToken();
    try {
      result = await axios.get<Menu>(`${this.baseNavManagerUrl.replace(/\/$/, '')}/menus/${contextId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (e) {
      throw new Error(`Failed to retrieve menu for context ${contextId}: ${e.toString()}`);
    }
    try {
      return transformNavMenusToContexts([result.data], true)[0];
    } catch (e) {
      throw new Error(`Failed to transform menu to SPA context for context ${contextId}: ${e.toString()}`);
    }
  }
}
