import { ReactSpaContext, RouteError } from '@nab/x-spa-react';
import { useContext } from 'react';
import { pushAnalyticsEvent } from '../../utils/analytics';
import constants, { miniapps, errors } from '../../utils/constants';
import Error, { createError } from './Error';

export function errorHeading(id: string) {
  switch (id) {
    case miniapps.LOGIN.IB.name:
    case miniapps.LOGIN.NABC.name:
      pushAnalyticsEvent(errors.MINIAPP, 'login:identity-load-error');
      return 'Login to NAB Hive';
    case miniapps.POLLINATE.name:
      pushAnalyticsEvent(errors.MINIAPP, 'account-summary:miniapp-load-error');
      return 'NAB Hive';
    case miniapps.USER_INFO.name:
      pushAnalyticsEvent(errors.MINIAPP, 'user-info-mini-app:miniapp-load-error');
      return 'My Details';
    case miniapps.WORKFLOW_MANAGEMENT.name:
      pushAnalyticsEvent(errors.MINIAPP, 'workflow:miniapp-load-error');
      return 'Request history';
    case miniapps.DX_FORM.name:
      pushAnalyticsEvent(errors.MINIAPP, 'pega-dx-forms:miniapp-load-error');
      return 'Service requests';
    default:
      return '';
  }
}

interface MiniAppErrorProps {
  app?: any;
  error?: string | Error;
  initError?: Error;
  routeError?: RouteError;
}

export function MiniAppError({ app, error, initError, routeError }: MiniAppErrorProps) {
  const { actions } = useContext(ReactSpaContext);
  actions.dispatchEvent(constants.POLLINATE_MINIAPP_ROLE_CHECK_COMPLETED);
  if (initError) {
    return Error({
      error: createError(errors.MINIAPP),
      showNavigation: true
    });
  } else if (routeError) {
    return Error({
      error: createError(errors.PAGE_NOT_FOUND),
      showNavigation: true
    });
  } else if (error) {
    const errorName = typeof error === 'string' ? error : (error as Error).name;
    return Error({
      error: createError(errorName, errorHeading(app?.id))
    });
  } else {
    return Error({
      error: createError(errors.MINIAPP, errorHeading(app?.id))
    });
  }
}

export default MiniAppError;
