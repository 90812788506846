/* istanbul ignore file */
import { AnalyticsMgr, DigitalDataStrategy, AppDRUMStrategy } from '@nab/nab-x-sdk-browser';
import {
  DafExchangeStrategy,
  DafInMemoryCacheStrategy,
  ContextConfig,
  ReactRootInitConfig,
  SeedTokenProviderWrapperStrategy,
  DafTokenExchangeError
} from '@nab/x-spa-react';
import GlobalSpa from '../../components/Spa/GlobalSpa/GlobalSpa';
import { getBearerToken, getPreferredLoginMethod } from '../../utils/session';
import { configMgr } from '../../utils/config-manager';
import { loadMiniappConfig } from '../../utils/miniapp-config/miniapp-config';
import { TENANTS, DEFAULT_ROUTE, errors, PORTAL_CONTEXT } from '../constants';
import { NavManagerMenuItem } from '../../components/Header/PollinateMenu/PollinateMenu';
import { compact, flatMap } from 'lodash';
import { HiveNavManagerContextProvider } from '../nav-manager/HiveNavManagerContextProvider';

const digitalDataStrategy = new DigitalDataStrategy();
const analytics = new AnalyticsMgr(digitalDataStrategy);
analytics.initPage({
  pageName: window.document.title,
  site: window.location.href
});

// Note: uncomment this to test error scenarios for nav manager
// In the future, we can remove this and integrate it into Cypress for test automation.
// axios.interceptors.request.use(
//   request => {
//     if(request.url.includes('/v2/merchantacq/nav-manager/menus')) {
//       request.headers['x-error-scenario'] = 'only_servicerequests'
//     }
//     return request;
//   }
// );

export interface ErrorContextConfig extends ContextConfig {
  error?: string;
}

const getAllRoutes = (navMenu: NavManagerMenuItem[]) => {
  const allRoutes = compact(
    flatMap(navMenu, item => {
      if (item) return item.route;
    })
  );
  return allRoutes;
};

export async function spaContextProvider(contextId: string): Promise<ContextConfig | ErrorContextConfig> {
  const emptyContextConfig: ErrorContextConfig = {
    apps: [],
    defaultRoute: '',
    id: 'invalid-context',
    menu: []
  };

  if (contextId === PORTAL_CONTEXT) {
    try {
      const navManagerContextProvider = new HiveNavManagerContextProvider(configMgr.get('NAVMANAGER_URL'));
      const { apps, menu } = await navManagerContextProvider.getContext(configMgr.get('NAVMANAGER_MENU_ID'));
      // Notes: check if user doesn't have access to default Pollinate route (/portal) hence they don't have access to Nab Hive service
      /* istanbul ignore if */
      if (menu && (menu.length === 0 || !getAllRoutes(menu).includes(DEFAULT_ROUTE))) {
        return {
          ...emptyContextConfig,
          id: PORTAL_CONTEXT,
          error: errors.NABHIVE_UNAUTHORISED
        };
      }

      return {
        id: PORTAL_CONTEXT,
        apps,
        menu,
        defaultRoute: menu && menu.length > 0 ? `/${menu[0].route}` : ''
      };
    } catch (e) {
      if ((e as Error).message === DafTokenExchangeError.INVALID_SEED_TOKEN) {
        return {
          ...emptyContextConfig,
          id: PORTAL_CONTEXT,
          error: DafTokenExchangeError.INVALID_SEED_TOKEN
        };
      }
      return emptyContextConfig;
    }
  } else {
    return loadMiniappConfig(contextId);
  }
}

export const getStartConfig = (): ReactRootInitConfig => {
  // Dynamic approach to set x-nab-tenant to 'nabc' or 'ib'
  let xNabTenant = 'nabc';

  // Tenant firstly dependant on route
  if (window.location.pathname === '/login/nabc') {
    xNabTenant = 'nabc';
  } else if (window.location.pathname === '/login/ib') {
    xNabTenant = 'ib';
  } else {
    // Note: Otherwise use preferred login method as x-nab-tenant
    const storedLoginTenantValue = getPreferredLoginMethod()?.toLowerCase();
    if (TENANTS.includes(storedLoginTenantValue)) {
      xNabTenant = storedLoginTenantValue;
    }
  }

  return {
    // React component which provides the main layout for this shell
    rootComponent: GlobalSpa,

    rootContextId: 'root',

    authConfig: {
      environment: process.env.X_RUNTIME_ENV || 'local',
      context: 'external',
      tokenType: 'Bearer',
      tokenSource: 'DAF',
      additionalRequestHeaders: {
        'x-nab-tenant': xNabTenant
      },
      requestTokenOnLoad: false
    },

    userConfig: {
      type: 'customer',
      org: 'NAB'
    },

    servicesConfig: {
      analytics: {
        strategy: digitalDataStrategy
      },
      rum: {
        strategy: new AppDRUMStrategy({
          // You will be able to get these values from APM team to configure it here.
          libraryUrl: configMgr.get('APPDYNAMICS_RUM_URL'),
          configValue: {
            // these values are environment specific and changes between nonprod & prod
            appKey: configMgr.get('APPDYNAMICS_RUM_KEY'),
            adrumExtUrlHttp: configMgr.get('APPDYNAMICS_RUM_EXT_URL'),
            adrumExtUrlHttps: configMgr.get('APPDYNAMICS_RUM_EXT_URL'),
            beaconUrlHttp: configMgr.get('APPDYNAMICS_BEACON_URL'),
            beaconUrlHttps: configMgr.get('APPDYNAMICS_BEACON_URL'),
            xd: {
              enable: false
            },
            spa: {
              spa2: true
            }
          }
        })
      }
    },

    authStrategies: [
      new SeedTokenProviderWrapperStrategy(() => Promise.resolve(getBearerToken()), {
        context: 'external',
        tokenType: 'Bearer',
        tokenSource: 'DAF'
      }),
      new DafExchangeStrategy(configMgr.get('DAF_TOKEN_URL'))
    ],

    authTokenCacheStrategy: new DafInMemoryCacheStrategy(),

    // Where this shell will be rendered
    hostElement: document.getElementById('root'),

    spaContextProvider
  };
};
