import React, { useContext, useState, useEffect, useMemo } from 'react';
import { MiniAppInstance, ReactSpaContext } from '@nab/x-spa-react';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import MiniAppError from '../../Error/MiniAppError';
import Error, { createError } from '../../Error/Error';
import { AppViewport } from '../../Spa/RootSpa/RootSpa.styles';
import constants, { analyticsEvents } from '../../../utils/constants';
import { getPreferredLoginMethod } from '../../../utils/session';
import { pushAnalyticsEvent } from '../../../utils/analytics';
import { ShellContext } from '../../Spa/GlobalSpa/GlobalSpa';

interface UserInfoProps {
  error?: string;
  setError: (value: string) => void;
}

export function UserInfo({ error, setError }: UserInfoProps) {
  const [isMiniappLoaded, setIsMiniappLoaded] = useState(false);
  const { activeAppInstance, actions } = useContext(ReactSpaContext);
  const { merchantsData, selectedMerchantId } = useContext(ShellContext);

  useEffect(() => {
    function userInfoMiniappLoaded() {
      pushAnalyticsEvent(analyticsEvents.MY_DETAILS_PAGE_VIEW);
      pushAnalyticsEvent(analyticsEvents.MINIAPP_LOADED);
      setIsMiniappLoaded(true);
    }
    actions.addEventListener(constants.USER_INFO_MINIAPP_LOADED, userInfoMiniappLoaded);
    return () => {
      actions.removeEventListener(constants.USER_INFO_MINIAPP_LOADED, userInfoMiniappLoaded);
    };
  });

  const renderMiniapp = useMemo(() => {
    if (error) {
      return <Error error={createError(error)} setError={setError} />;
    }

    if (!(selectedMerchantId && merchantsData)) {
      return <MiniAppLoading />;
    }

    let activationStatus = null;
    for (const merchantData of merchantsData) {
      if (`${merchantData.merchantId}` === `${selectedMerchantId}`) {
        activationStatus = merchantData.activationStatus;
        break;
      }
    }

    // TODO: Remove DE115319 workaround once DAF has implemented dynamic user roles
    activeAppInstance.appConfig = {
      ...activeAppInstance.appConfig,
      activationStatus: activationStatus,
      role: '  ', // Notes: DE115319: Explicitly pass an empty string so we don't display anything for NABC customers. Otherwise, it will display "Merchant Owner" incorrectly for other roles (Employee, etc.)
      tenant: getPreferredLoginMethod() === constants.LOGIN_METHOD_IB ? 'ib' : 'nabc'
    };

    return (
      <>
        {!isMiniappLoaded && <MiniAppLoading />}
        <MiniAppInstance appInstance={activeAppInstance} loadingSlot={() => <></>} errorSlot={MiniAppError} />
      </>
    );
  }, [error, selectedMerchantId, merchantsData, activeAppInstance, isMiniappLoaded, setError]);

  return <AppViewport id="appViewPort">{renderMiniapp}</AppViewport>;
}

export default UserInfo;
