import { useContext, useState, useEffect } from 'react';
import { initialize, LDUser } from 'launchdarkly-js-client-sdk';
import { MiniAppContext } from '@nab/nab-x-react';
import { configMgr } from '../utils/config-manager';
import { getUserProfileFromStorage } from '../utils/session';

const useLaunchDarkly = () => {
  const { user } = useContext(MiniAppContext);
  const [launchDarklyIsReady, setLaunchDarklyIsReady] = useState(false);
  const [launchDarklyError, setLaunchDarklyError] = useState(false);
  const [launchDarklyFlags, setLaunchDarklyFlags] = useState(null);
  const [launchDarklyUser, setLaunchDarklyUser] = useState(null);

  const userProfile = getUserProfileFromStorage();
  const LAUNCH_DARKLY_CLIENT_ID = configMgr.get('LAUNCHDARKLY_CLIENTID');

  useEffect(() => {
    // The following ldUser object is based on the object created by the nabx launch darkly code
    // https://github.aus.thenational.com/NAB-X/nab-x-sdk/blob/develop/packages/consumer/sdk-browser/src/FlagMgr/FlagStrategies/LaunchDarklyStrategy/LaunchDarklyClient.ts#L44-L52
    const ldUser: LDUser = {
      key: userProfile?.userId,
      anonymous: user.authenticated,
      custom: {
        ...user.details,
        userId: userProfile?.userId,
        channel: userProfile?.channel,
        host: window.location.host,
        type: 'customer',
        org: 'NAB'
      }
    };

    let client;
    if (ldUser.key) {
      client = initialize(LAUNCH_DARKLY_CLIENT_ID, ldUser, {
        sendEventsOnlyForVariation: true
      });
    } else {
      client = initialize(LAUNCH_DARKLY_CLIENT_ID, { key: 'anon', anonymous: true });
    }

    client
      .waitForInitialization()
      .then(() => {
        setLaunchDarklyFlags(client.allFlags());
        setLaunchDarklyIsReady(true);
        if (ldUser.key) {
          setLaunchDarklyUser(ldUser);
        }
      })
      .catch(() => {
        setLaunchDarklyError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { launchDarklyIsReady, launchDarklyUser, launchDarklyError, launchDarklyFlags };
};

export default useLaunchDarkly;
