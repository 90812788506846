import { Link } from '@nab/nui-react';

export const errorsDescriptions = {
  MINIAPP: (
    <>
      We've encountered an unexpected problem.
      <br />
      Please wait a few moments and try again.
    </>
  ),
  OFFLINE: (
    <>
      We are unable to connect you to NAB Hive.
      <br />
      Please check your internet connection and try again.
    </>
  ),
  NABHIVE_UNAUTHORISED: (
    <div>
      <p>You don't have access to view NAB Hive with those login details.</p>
      <p>
        If you have any other merchant services enquiries, please call us on <Link href="tel:1300 755 287">1300 755 287</Link> (Monday to Friday 8am
        to 6pm AEST) for further assistance.
      </p>
    </div>
  ),
  NABHIVE_UNREGISTERED_BUSINESS: (
    <div>
      <p>Your business hasn't been registered for NAB Hive just yet.</p>
      <p>
        If you have any other merchant services enquiries, please call us on <Link href="tel:1300 755 287">1300 755 287</Link> (Monday to Friday 8am
        to 6pm AEST) for further assistance.
      </p>
    </div>
  ),
  IFRAME_notFound: <>We can't find the page you were looking for.</>,
  TIMEOUT: <>Please login again to continue.</>,
  LOGGED_IN: <>Please login again to continue.</>,
  IFRAME_internalServerError: <>An error occurred and your request couldn’t be completed. Please wait a few moments and try again.</>,
  UNKNOWN: <>An error occurred and your request couldn’t be completed. Please wait a few moments and try again.</>,
  USER_INFO_unknown: <>An error occurred and your request couldn’t be completed. Please wait a few moments and try again.</>,
  IFRAME_unauthorised: (
    <>
      Please review your account permissions or contact{' '}
      <Link href="https://www.nab.com.au/business/payments-and-merchants/merchant-support-centre">Merchant Support Centre</Link> on{' '}
      <Link href="tel:1300 755 287">1300 755 287</Link>.
    </>
  ),
  MERCHANT_nonexistentMid: (
    <>
      Please contact <Link href="https://www.nab.com.au/business/payments-and-merchants/merchant-support-centre">Merchant Support Centre</Link> on{' '}
      <Link href="tel:1300 755 287">1300 755 287</Link> to request access.
    </>
  ),
  IFRAME: (
    <>
      An unexpected error occurred and you have been logged out. Please contact{' '}
      <Link href="https://www.nab.com.au/business/payments-and-merchants/merchant-support-centre">Merchant Support Centre</Link> on{' '}
      <Link href="tel:1300 755 287">1300 755 287</Link> or login again.
    </>
  ),
  USER_INFO: (
    <>
      An unexpected error occurred and you have been logged out. Please contact{' '}
      <Link href="https://www.nab.com.au/business/payments-and-merchants/merchant-support-centre">Merchant Support Centre</Link> on{' '}
      <Link href="tel:1300 755 287">1300 755 287</Link> or login again.
    </>
  )
};
