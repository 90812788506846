import axios from 'axios';
import { ContextConfig } from '@nab/x-spa-react';
import { configMgr } from '../../utils/config-manager';
import { miniapps, PORTAL_CONTEXT } from '../constants';

function getConfig(key, defaultValue?) {
  const value = configMgr.get(key, defaultValue);
  if (value) {
    return value;
  }
  throw new Error('Required config missing');
}

export const loadMiniappConfig = async (contextId: string): Promise<ContextConfig> => {
  try {
    const config = (await axios.get(`config/${getConfig('BUILD_ENVIRONMENT', 'local')}/context-${contextId}.json`)).data;
    if (contextId === PORTAL_CONTEXT || contextId === 'login-identity-miniapp') {
      for (const app in config.apps) {
        if (!config.apps[app].appMetaData?.contextId) {
          config.apps[app].appMetaData.assetManifestUrl = getConfig(
            config.apps[app].appMetaData?.assetManifestUrl,
            'http://localhost:3000/asset-manifest.json'
          );
          let appConfig = config.apps[app].appMetaData.properties?.appConfig;
          if (appConfig) {
            const appId = config.apps[app].appMetaData.id;
            const environment = getConfig('IDENTITY_ENVIRONMENT') || 'local';

            if (appId === miniapps.LOGIN.NABC.name) {
              if (environment === 'local') {
                appConfig = {
                  ...appConfig,
                  userNameStorage: getConfig(appConfig.userNameStorage),
                  userNameStorageKey: getConfig(appConfig.userNameStorageKey)
                };
              }
            }

            config.apps[app].appMetaData.properties.appConfig = {
              ...appConfig,
              scope: getConfig(appConfig.scope),
              clientId: getConfig(appConfig.clientId),
              environment
            };
          }
        }
      }
    }
    return config;
  } catch (e) {
    throw new Error(`Failed to retrieve context ${contextId}`);
  }
};
