import { configMgr } from '../utils/config-manager';
import axios, { AxiosError } from 'axios';
import { getBearerToken } from '../utils/session';

export async function revokeToken(attempts: number, existingToken?: string) {
  const body = {
    client_id: configMgr.get('DAF_CLIENT_ID'),
    token: existingToken ? existingToken : getBearerToken(),
    token_type_hint: 'access_token'
  };
  const headers = {
    'Content-Type': 'application/json'
  };

  try {
    await axios.post(configMgr.get('DAF_REVOKE_URL'), body, { headers });
  } catch (reason) {
    const axiosError = reason as AxiosError;
    if (axiosError?.response && attempts === 0 && axiosError?.response?.status === 500) {
      await revokeToken(++attempts, existingToken);
    }
  }
}
