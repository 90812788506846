import React, { useState } from 'react';
import { Modal, Paragraph, Row, Col, Button, Link } from '@nab/nui-react';
import { ModalContentWrapper, ModalDescription } from './LoginMethodModal.styles';

interface LoginMethodModalProps {
  setOpenModal: (value: boolean) => void;
}

export function LoginMethodModal({ setOpenModal }: LoginMethodModalProps) {
  const [isClosing, setIsClosing] = useState(false);

  /* istanbul ignore next */
  const modalHasClosed = () => {
    setIsClosing(false);
    setOpenModal(false);
  };

  return (
    <Modal
      id="loginMethodModal"
      rootNode={document.getElementById('appViewPort') || document.body}
      data-testid="loginMethodModal"
      label="loginMethodModal"
      hasClose={true}
      title="Which login option to use"
      titleVariant="h2"
      titleShouldResize
      beginClose={isClosing}
      size="medium"
      onClose={modalHasClosed}
    >
      <ModalContentWrapper>
        <Row bottomSpacing="sm" data-testid="modalDesc">
          <Col className="alignLeft">
            <Paragraph variant="bold" className="modalTitle">
              Log in with NAB Connect
            </Paragraph>
            <ModalDescription>
              <Paragraph>Use this option if your business banks using NAB Connect.</Paragraph>
              <Paragraph>
                Your User ID will generally be 10-digits long and start with 620, unless you’ve previously set a Preferred User ID for yourself.
              </Paragraph>
            </ModalDescription>
            <Paragraph variant="bold" className="modalTitle">
              Log in with NAB Internet Banking
            </Paragraph>
            <ModalDescription>
              <Paragraph>Use this option if your business banks using NAB Internet Banking or the NAB Mobile Banking app.</Paragraph>
            </ModalDescription>
            <Paragraph>
              For businesses using Internet Banking, your business NAB ID will be different to the personal NAB ID you may find on the back of your
              card. Your business NAB ID would have been given to you in person by your business banker or over the counter at a branch. If you’re new
              to NAB, you may have also received it in an SMS when you joined the bank.
            </Paragraph>
            <Paragraph data-testid="finalDesc">
              If you’re having trouble finding your business NAB ID, you can call us on <Link href="tel:13 10 12">13 10 12</Link> between 8am - 8pm
              Mon-Fri, 9am - 6pm Sat-Sun (all times AEST/AEDT).
            </Paragraph>
          </Col>
        </Row>
        <Row data-testid="buttonRow">
          <Button
            type="button"
            variant="primary"
            label="Ok, got it"
            data-testid="aknowledgeButton"
            onClick={
              /* istanbul ignore next */
              () => {
                setIsClosing(true);
              }
            }
          />
        </Row>
      </ModalContentWrapper>
    </Modal>
  );
}

export default LoginMethodModal;
