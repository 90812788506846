/* istanbul ignore file */
import { IConfigMgr, ConfigMgr } from '@nab/nab-x-sdk-core';

export let configMgr: IConfigMgr;

export async function initiliazeConfigMgr() {
  configMgr = new ConfigMgr('nab-hive-shell');
  await configMgr.init();
  return configMgr;
}
