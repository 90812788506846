import styled from 'styled-components';
import { media } from '@nab/nui-react';
import { styling } from '../../utils/constants';

const LogoImageMd = media.md`
  margin: 8px 36px;
`;

const LogoutButtonLg = media.lg`
  margin-left: inherit;
`;

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  display: flex;
  align-items: center;
  height: ${styling.HEADER_HEIGHT};

  &.unscrollable {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }

  & > a {
    height: 0px;
    width: 0px;
    overflow: hidden;
    color: transparent !important;
    z-index: 1000001;
    cursor: default;
    transition: border 1ms;
    margin-left: auto;

    &:focus {
      font-size: ${({ theme }) => theme.font.size.xs};
      font-weight: ${({ theme }) => theme.font.weight.light};
      transition: 200ms ease-in;
      width: auto;
      height: auto;
      text-decoration: none;
      cursor: pointer;
      color: ${({ theme }) => theme.color.white} !important;
    }
  }

  & > nav {
    margin-left: auto;
  }

  & > button {
    border-radius: 0 !important;
    height: 100%;
  }

  #hamburgerMenuButton {
    margin-left: 16px;
  }

  #logoutButton {
    width: 199px;
    font-size: ${({ theme }) => theme.font.size.xs};
    margin-right: 32px;
    margin-left: auto;

    ${LogoutButtonLg}
  }

  #logoutButtonMobile {
    background-color: ${({ theme }) => theme.color.primary};
    min-width: 48px;
    width: 56px;
    margin-left: auto;
  }

  & > h2 {
    min-inline-size: fit-content;
    margin-right: 16px;
  }
`;

export const LogoImage = styled.img`
  width: 140px;
  height: 45px;
  margin: 8px;

  ${LogoImageMd}
`;

export const LinkList = styled.ul`
  color: #f2f2f2;
  margin: 0 32px 0 0;
  padding: 0;
  display: flex;
  list-style: none;

  & > li:last-child {
    padding-left: 10px;
  }

  a {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`;

export const ScreenReaderSpan = styled.span`
  position: absolute;
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
`;
