import React, { useEffect, useState } from 'react';
import { MiniAppInstance, SpaProps } from '@nab/x-spa-react';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import MiniAppError from '../../Error/MiniAppError';
import Header from '../../Header/Header';
import { HeaderWrapper, AppViewport } from '../RootSpa/RootSpa.styles';
import { LoginFormWrapper, LoginBackgroundImage, AltLinkWrapper, InnerLoginFormWrapper } from './Login.styles';
import { errors, analyticsEvents, miniapps, FEATURE_FLAGS } from '../../../utils/constants';
import { pushAnalyticsEvent } from '../../../utils/analytics';
import loginBackgroundImgSrc from '../../../images/login_background.jpg';
import { useAuth } from '../../../hooks/useAuth';
import { logout } from '../../../utils/session';
import { H1, Link, Paragraph } from '@nab/nui-react';
import Footer from '../../Footer/Footer';
import LoginSelector from '../../LoginSelector/LoginSelector';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

interface LoginSpaProps extends SpaProps {
  rootError?: string;
}

const LoginSpaInstance: React.FC<SpaProps> = ({ activeAppInstance, initError, routeError, rootError }: LoginSpaProps) => {
  const { loading, isAuthenticated } = useAuth();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(rootError);
  const { flagValue: isIBLoginEnabled } = useFeatureFlag(FEATURE_FLAGS.IB_USER_LOGIN, true);

  const isNabc = activeAppInstance?.app.id === miniapps.LOGIN.NABC.name;
  const nabcPaddingClass = isNabc ? 'nabcLeftPadding' : '';
  const errorCheck = !routeError && !initError;

  function alternateLoginPage(isNabc: boolean) {
    return isNabc ? (
      <Link
        href={miniapps.LOGIN.IB.route}
        onClick={() => {
          altLinkAnalytics('nab-connect');
        }}
      >
        Log in using Internet Banking
      </Link>
    ) : (
      <Link
        href={miniapps.LOGIN.NABC.route}
        onClick={() => {
          altLinkAnalytics('nab-internet-banking');
        }}
      >
        Log in using NAB Connect
      </Link>
    );
  }

  useEffect(() => {
    if (hasLoaded && !loading && !routeError && !initError && !isAuthenticated) {
      if (window.location.pathname === miniapps.LOGIN.IB.route) {
        pushAnalyticsEvent(analyticsEvents.LOGIN_IB_PAGE_VIEW);
      } else if (window.location.pathname === miniapps.LOGIN.NABC.route) {
        pushAnalyticsEvent(analyticsEvents.LOGIN_NABC_PAGE_VIEW);
      } else if (window.location.pathname === miniapps.LOGIN.SELECTOR.route) {
        pushAnalyticsEvent(analyticsEvents.MINIAPP_LOADED);
      }
    }
  }, [setHasLoaded, hasLoaded, loading, routeError, initError, isAuthenticated]);

  useEffect(() => {
    async function logOutFunction() {
      await logout();
    }

    if (!initError && !routeError) {
      setHasLoaded(true);

      if (isAuthenticated) {
        setError(errors.LOGGED_IN);
        pushAnalyticsEvent(errors.LOGGED_IN);
        logOutFunction();
      }
    } else {
      if (routeError) {
        pushAnalyticsEvent(errors.PAGE_NOT_FOUND, window.location.pathname + window.location.hash);
      }
    }
  }, [initError, routeError, isAuthenticated]);

  function altLinkAnalytics(pageName: string) {
    pushAnalyticsEvent(analyticsEvents.SELECT_LOGIN_ID_USER_MOMENT_ON_PAGES, pageName);
  }

  return (
    <>
      <HeaderWrapper>
        <Header showPollinateMenu={false} />
      </HeaderWrapper>
      <AppViewport>
        {initError || routeError || error ? (
          <MiniAppError error={error} initError={initError} routeError={routeError} />
        ) : (
          <>
            <LoginFormWrapper id="bodyContent">
              {activeAppInstance?.app.id === miniapps.LOGIN.SELECTOR.name ? (
                <LoginSelector isIBLoginEnabled={isIBLoginEnabled} />
              ) : (
                <InnerLoginFormWrapper data-testid={!isNabc ? 'ibFormWrapper' : 'nabcFormWrapper'}>
                  {errorCheck && (
                    <>
                      <H1 tabindex="-1" variant="impact" className={nabcPaddingClass}>
                        Log into NAB Hive
                      </H1>
                      <Paragraph className={nabcPaddingClass}>
                        {isNabc ? 'Log in using your NAB Connect details.' : 'Log in using your business Internet Banking details.'}
                      </Paragraph>
                    </>
                  )}
                  <MiniAppInstance appInstance={activeAppInstance} loadingSlot={MiniAppLoading} errorSlot={MiniAppError} />
                  {isIBLoginEnabled && (
                    <AltLinkWrapper className={nabcPaddingClass}>
                      {errorCheck && (
                        <>
                          <Paragraph>or  </Paragraph>
                          {alternateLoginPage(isNabc)}
                        </>
                      )}
                    </AltLinkWrapper>
                  )}
                </InnerLoginFormWrapper>
              )}
            </LoginFormWrapper>
            <LoginBackgroundImage src={loginBackgroundImgSrc} alt="" />
          </>
        )}
      </AppViewport>
      <Footer />
    </>
  );
};

export default LoginSpaInstance;
