import styled from 'styled-components';
import { media } from '@nab/nui-react';

export const ModalContentWrapper = styled.div`
  margin-top: 24px;

	[data-testid="modalDesc"] {
		overflow-y: scroll;
    max-height: 476px;
    margin-bottom: 24px;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.color.grey90};
    padding: 24px;

    ${media.sm`
      padding: 0px;
      border: unset;
    `}
	}

  [data-testid="finalDesc"] {
		margin-top 16px;
  }

  [data-testid="buttonRow"] {
    margin: 0;
    justify-content: right;
  }

	.alignLeft {
		text-align: left;
	}

  .modalTitle {
    margin-bottom: 8px;
  }

  button {
    width: 100%;

    ${media.sm`
      width: auto;
    `}
  }
`;

export const ModalDescription = styled.div`
  margin-top: 0;
  margin-bottom: 24px;
`;
