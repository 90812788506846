/* istanbul ignore file */
import React, { useMemo } from 'react';
import { MiniAppInstance, AppInstance, InstanceLifecycleState, RouteError } from '@nab/x-spa-react';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import MiniAppError from '../../Error/MiniAppError';

type ViewportProps = {
  appInstance: AppInstance;
  appState?: InstanceLifecycleState;
  initError?: Error;
  routeError?: RouteError;
};

const Viewport: React.FC<ViewportProps> = ({ appInstance, appState, initError, routeError }) => {
  const errorComponent = appState && appState === InstanceLifecycleState.START_ERROR ? MiniAppError : null;

  return useMemo(
    () => (
      <>
        {initError && <MiniAppError app={appInstance?.app} initError={initError} />}
        {routeError && <MiniAppError app={appInstance?.app} routeError={routeError} />}
        {appInstance && <MiniAppInstance appInstance={appInstance} loadingSlot={MiniAppLoading} errorSlot={errorComponent} />}
      </>
    ),
    [appInstance, errorComponent, routeError, initError]
  );
};
export default Viewport;
