import React, { useContext, useState, useEffect } from 'react';
import { MiniAppInstance, ReactSpaContext } from '@nab/x-spa-react';
import { startsWith, endsWith } from 'lodash';
import { MerchantData } from '../../Spa/RootSpa/RootSpa';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import MiniAppError from '../../Error/MiniAppError';
import Error, { createError } from '../../Error/Error';
import { NavManagerMenuItem } from '../../Header/PollinateMenu/PollinateMenu';
import constants, { analyticsEvents, errors, miniapps, ROUTE_NAVIGATION_KEYS } from '../../../utils/constants';
import { getBearerToken, getPreferredLoginMethod, navigateToLogin } from '../../../utils/session';
import { pushAnalyticsEvent } from '../../../utils/analytics';
import { AppViewport } from '../../Spa/RootSpa/RootSpa.styles';
import { getPageNameForAnalytics } from '../../../utils/analytics';
import { ShellContext } from '../../Spa/GlobalSpa/GlobalSpa';

interface PollinateProps {
  error?: string;
  setError: (value: string) => void;
  merchantData?: MerchantData;
}

function findMenuItemForKey(key: string, menu: NavManagerMenuItem[]): NavManagerMenuItem {
  const itemRoute = ROUTE_NAVIGATION_KEYS.find(k => k.key === key)?.route;
  return itemRoute ? findMenuItemForRoute(itemRoute, menu) : null;
}

export function findMenuItemForRoute(route: string, menu: NavManagerMenuItem[]): NavManagerMenuItem {
  const menuItem = menu?.reduce((previous, current) => {
    if (previous) return previous;

    if (
      current.route === route ||
      (route === '' && current.route === miniapps.POLLINATE.route) ||
      (endsWith(current.route, route) && startsWith(route, '#'))
    ) {
      return current;
    }
    if (current.children) return findMenuItemForRoute(route, current.children);
  }, null);

  return menuItem;
}

const getMenuItemKey = (menuItem: NavManagerMenuItem, route?: string) => {
  let navItem;
  if (!menuItem) return null;
  if (route) {
    navItem = ROUTE_NAVIGATION_KEYS.find(k => k.route === route);
  } else {
    navItem = ROUTE_NAVIGATION_KEYS.find(k => endsWith(menuItem.route, k.route));
  }
  return navItem?.key;
};

export function Pollinate({ error, setError }: PollinateProps) {
  const [currentPageHash, setCurrentPageHash] = useState<string>(window.location.hash);
  const { activeAppInstance, actions, context } = useContext(ReactSpaContext);
  const pollinateMenu = context?.menu;
  const { selectedMerchantId, merchantsData, updateCurrentRoute } = useContext(ShellContext);

  function pushPollinateMiniappInsights(data: Record<string, any>) {
    pushAnalyticsEvent(analyticsEvents.POLLINATE_INSIGHTS, '', data);
  }

  function pollinateMiniappScrolled(key: string) {
    window.scrollTo(0, 0);
  }

  function pollinateMiniappNavigated(key: string) {
    // If logged out, navigate to login
    if (!getBearerToken()) {
      navigateToLogin();
    }

    if (key === 'OVERVIEW') {
      window.location.hash = '';
      return;
    }

    let newPageHash = findMenuItemForKey(key, pollinateMenu)?.route;

    if (newPageHash) {
      if (newPageHash.indexOf('#') >= 1) {
        newPageHash = newPageHash.slice(newPageHash.indexOf('#'));
      }

      setCurrentPageHash(newPageHash);
      window.location.hash = newPageHash;
    }
  }

  async function handleHashChanged() {
    const newPageHash = window.location.hash;

    // Ignore skip to main content link
    if (newPageHash === 'bodyContent') {
      return;
    }
    setError(null);
    if (!navigator.onLine && error !== errors.OFFLINE) {
      setError(errors.OFFLINE);
    }
    if (currentPageHash !== newPageHash) {
      const key = getMenuItemKey(findMenuItemForRoute(newPageHash, pollinateMenu), newPageHash);

      if (key) {
        await actions.dispatchEvent(constants.NAVIGATION, key);
      } else {
        setError(errors.PAGE_NOT_FOUND);
      }
      setCurrentPageHash(newPageHash);
    }
  }

  useEffect(() => {
    switch (error) {
      case errors.OFFLINE:
        pushAnalyticsEvent(errors.OFFLINE, getPageNameForAnalytics(window.location.hash.replace('#', ''), pollinateMenu));
        break;
    }
  }, [error, pollinateMenu]);

  // Check if page exists on refresh
  useEffect(() => {
    if (pollinateMenu) {
      const menuItemKey = findMenuItemForRoute(currentPageHash, pollinateMenu);
      if (selectedMerchantId && merchantsData && !menuItemKey && getBearerToken()) {
        setError(errors.PAGE_NOT_FOUND);
      }
    }
  }, [selectedMerchantId, merchantsData, currentPageHash, pollinateMenu, setError]);

  useEffect(() => {
    actions.addEventListener(constants.POLLINATE_MINIAPP_INSIGHTS, pushPollinateMiniappInsights);
    actions.addEventListener(constants.POLLINATE_MINIAPP_NAVIGATED, pollinateMiniappNavigated);
    actions.addEventListener(constants.POLLINATE_MINIAPP_SCROLL_TOP, pollinateMiniappScrolled);
    window.addEventListener('hashchange', handleHashChanged);

    return () => {
      actions.removeEventListener(constants.POLLINATE_MINIAPP_INSIGHTS, pushPollinateMiniappInsights);
      actions.removeEventListener(constants.POLLINATE_MINIAPP_NAVIGATED, pollinateMiniappNavigated);
      actions.removeEventListener(constants.POLLINATE_MINIAPP_SCROLL_TOP, pollinateMiniappScrolled);
      window.removeEventListener('hashchange', handleHashChanged);
    };
  });

  useEffect(() => {
    if (updateCurrentRoute) {
      updateCurrentRoute(currentPageHash);
    }
  }, [currentPageHash]);

  function renderMiniapp() {
    if (error) {

      if (error === errors.OFFLINE) {
        const heading = findMenuItemForRoute(currentPageHash, pollinateMenu)?.label;
        return <Error error={createError(error, heading)} setError={setError} />;
      }
      return <Error error={createError(error)} setError={setError} />;
    }

    if (!selectedMerchantId || !merchantsData) {
      return <MiniAppLoading />;
    }

    if (activeAppInstance) {
      activeAppInstance.appConfig.menu = pollinateMenu;
      const appView = getMenuItemKey(findMenuItemForRoute(window.location.hash, pollinateMenu));
      activeAppInstance.params = {
        ...activeAppInstance.params,
        merchantId: selectedMerchantId,
        view: appView || 'OVERVIEW',
        tenant: getPreferredLoginMethod()?.toLowerCase() || 'nabc' // Notes: Will need to pass the tenant (ib, nabc) explicitly
      };
    }

    return <MiniAppInstance appInstance={activeAppInstance} loadingSlot={MiniAppLoading} errorSlot={MiniAppError} />;
  }

  return <AppViewport id="appViewPort">{renderMiniapp()}</AppViewport>;
}

export default Pollinate;
