import styled from 'styled-components';
import { media } from '@nab/nui-react';
import { styling } from '../../utils/constants';

export const ErrorWrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 100%;
  text-align: center;
  padding: 40px 32px 16px;
  background-color: ${({ theme }) => theme.color.white};
  grid-row-start: 1;
  grid-column-start: 1;
  min-height: calc(100vh - ${styling.MOBILE_FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT});
  min-height: calc(calc(var(--vh, 1vh) * 100) - ${styling.MOBILE_FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT});

  & > h2 {
    margin-bottom: 24px;
  }

  & > button {
    margin: 40px 0;
  }

  & > #Error_message_desc {
    text-align: center;

    & > a {
      vertical-align: 0;
    }
  }

  // linebreak for NABHIVE_UNAUTHORISED & NABHIVE_UNREGISTERED_BUSINESS
  & > #Error_message_desc p {
    + p {
      margin-top: 8px;
    }
    a {
      vertical-align: 0px;
    }
  }

  & > #Error_message_desc > div {
    display: inline-block;
    max-width: 440px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;

    ${media.xs`
      max-width: 100%;
    `}
  }

  ${media.sm`
    min-height: calc(100vh - ${styling.FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT});
    min-height: calc(calc(var(--vh, 1vh) * 100) - ${styling.FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT});
  `};
`;

export const ErrorImage = styled.img`
  width: 152px;
  height: 48px;
  margin: 24px 0;
`;
