import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MiniAppInstance, ReactSpaContext } from '@nab/x-spa-react';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import MiniAppError, { errorHeading } from '../../Error/MiniAppError';
import Error, { createError } from '../../Error/Error';
import { AppViewport } from '../../Spa/RootSpa/RootSpa.styles';
import constants, { analyticsEvents, errors, FEATURE_FLAGS, miniapps } from '../../../utils/constants';
import { pushAnalyticsEvent } from '../../../utils/analytics';
import { ShellContext } from '../../Spa/GlobalSpa/GlobalSpa';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

interface DxFormsProps {
  error?: string;
  setError: (value: string) => void;
}

export function DxForms({ error, setError }: DxFormsProps) {
  const { activeAppInstance, actions } = useContext(ReactSpaContext);
  const { selectedMerchantId, merchantsData } = useContext(ShellContext);
  const { flagValue: isPegaDXFormsAvailable } = useFeatureFlag(FEATURE_FLAGS.MINIAPP_PEGA_FORMS, true);
  const [currentFormMerchantId, setCurrentFormMerchantId] = useState(null);

  useEffect(() => {
    // Notes: make sure we only set the 1st valid merchant id from our context
    if (!currentFormMerchantId && selectedMerchantId !== null) {
      setCurrentFormMerchantId(selectedMerchantId);
    }
  }, [selectedMerchantId, setCurrentFormMerchantId])

  useEffect(() => {
    if (!currentFormMerchantId) return;

    function dxFormsMiniappLoaded() {
      if (window.location.pathname === miniapps.DX_FORM.route.business) {
        pushAnalyticsEvent(analyticsEvents.MINIAPP_LOADED, miniapps.DX_FORM.route.business);
      } else if (window.location.pathname === miniapps.DX_FORM.route.store) {
        pushAnalyticsEvent(analyticsEvents.MINIAPP_LOADED, miniapps.DX_FORM.route.store);
      }
    }
    actions.addEventListener(constants.PEGA_FORMS_MINIAPP_LOADED, dxFormsMiniappLoaded);
    return () => {
      actions.removeEventListener(constants.PEGA_FORMS_MINIAPP_LOADED, dxFormsMiniappLoaded);
    };
  }, [currentFormMerchantId]);

  useEffect(() => {
    // Note: Feature flag - do not show the page if our
    if (!isPegaDXFormsAvailable) {
      setError(errors.PAGE_NOT_FOUND);
    }
  }, [isPegaDXFormsAvailable]);

  const renderMiniapp = useMemo(() => {
    if (error) {
      return <Error error={createError(error, errorHeading(activeAppInstance?.id))} setError={setError} />;
    }

    if (!(currentFormMerchantId && merchantsData)) {
      return <MiniAppLoading />;
    }

    let caseType = '';
    if (window.location.pathname === miniapps.DX_FORM.route.business) {
      caseType = 'NAB-UWF-Work-Service-SS-MFUpdateMerchantDetails';
    } else if (window.location.pathname === miniapps.DX_FORM.route.store) {
      caseType = 'NAB-UWF-Work-Service-SS-MFUpdateStoreDetails';
    }

    activeAppInstance.appData = {
      ...activeAppInstance.appData,
      launchContext: caseType !== '' ? 'serviceRequests' : activeAppInstance.appData?.launchContext || 'serviceRequests'
    };

    activeAppInstance.params = {
      caseType,
      merchantId: currentFormMerchantId,
      wfmPath: `${miniapps.WORKFLOW_MANAGEMENT.route}`,
      context: 'customer',
      shell: 'Hive'
    };

    return (
      <div key={`instance-${currentFormMerchantId}`}>
        <MiniAppInstance appInstance={activeAppInstance} loadingSlot={MiniAppLoading} errorSlot={MiniAppError} />
      </div>
    );
  }, [error, currentFormMerchantId, merchantsData, window.location.pathname, activeAppInstance.params?.caseType, activeAppInstance.appData?.launchContext]);

  return <AppViewport id="appViewPort">{renderMiniapp}</AppViewport>;
}

export default DxForms;
