import styled from 'styled-components';
import { Spinner } from '@nab/nui-react';

export const LoadingWrapper = styled.div`
  min-height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  flex: none;
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 1;
  top: 0px;
  left: 0px;
`;

export function MiniAppLoading() {
  return (
    <LoadingWrapper>
      <Spinner size="xl" message="Loading NAB Hive" isOnDarkBg />
    </LoadingWrapper>
  );
}

export default MiniAppLoading;
