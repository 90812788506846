import React, { useEffect, useState, useContext, useMemo } from 'react';
import { EmbeddedMiniApp, ReactSpaContext, ReactSpaProps } from '@nab/x-spa-react';
import { HomeIcon, ChevronDownIcon, H2, useMediaQuery } from '@nab/nui-react';
import { endsWith } from 'lodash';
import { MenuWrapper, Menu, MenuItem, SubMenuWrapper, SubMenu, MerchantSelectorWrapper } from './PollinateMenu.styles';
import { CONTEXT_BASE_URL, POLLINATE_MINIAPP_ROUTE } from '../../../utils/constants';
import { ShellContext } from '../../Spa/GlobalSpa/GlobalSpa';

interface PollinateMenuProps {
  menu: NavManagerMenuItem[];
  currentRoute?: string;
  setError?: (value: string) => void;
}

interface ShellConfig {
  featureFlags?: string;
  excludeIBUsers?: boolean;
}

export interface NavManagerMenuItem {
  children?: NavManagerMenuItem[];
  description?: string;
  icon?: string;
  isAbsolute?: boolean;
  label: string;
  route?: string;
  shellConfig?: ShellConfig;
}

interface OpenMenu {
  close?: string;
  open?: string;
}

export function containsCurrentRoute(item: NavManagerMenuItem, currentRoute: string): boolean {
  const itemRoute = item.route;

  // If item starts with pollinate route '/portal'
  if (itemRoute && itemRoute.includes(POLLINATE_MINIAPP_ROUTE)) {
    if (
      itemRoute === currentRoute ||
      itemRoute.replace(`${POLLINATE_MINIAPP_ROUTE}/`, '') === currentRoute ||
      (currentRoute === '' && itemRoute === POLLINATE_MINIAPP_ROUTE)
    ) {
      return true;
    }
  } else if (
    itemRoute === `/${currentRoute}` ||
    (item.route === POLLINATE_MINIAPP_ROUTE && currentRoute?.length < 1) ||
    currentRoute?.indexOf(itemRoute) >= 0
  ) {
    // If item starts with other routes or with suffixes
    return true;
  } else {
    // Check for any child menu item whether they are active / highlighted
    let isActive = false;
    if (item.children) {
      item.children.forEach(function(child) {
        const childRoute = child.route;
        isActive = isActive || currentRoute?.indexOf(childRoute) >= 0 || childRoute.replace(`${POLLINATE_MINIAPP_ROUTE}/`, '') === currentRoute;
      });
    }
    return isActive;
  }
}

export function PollinateMenu({ menu, currentRoute, setError }: PollinateMenuProps) {
  const [openMenu, setOpenMenu] = useState<OpenMenu>({});
  const { actions } = useContext(ReactSpaContext) as ReactSpaProps;
  const { merchantAdminAppConfig, merchantsData } = useContext(ShellContext);
  const isMediumScreen = useMediaQuery('(min-width: 0px) and (max-width: 1200px)');

  let timeout: ReturnType<typeof setTimeout>;

  async function navigateToItem(e, item: NavManagerMenuItem, hash: string) {
    e.preventDefault();

    if (item.route !== '' && hash && endsWith(item.route, hash)) {
      const currentPathName = window.location.pathname;
      let itemRouteWithoutHash = item.route.replace(hash, '');

      if (endsWith(itemRouteWithoutHash, '/')) {
        itemRouteWithoutHash = itemRouteWithoutHash.slice(0, itemRouteWithoutHash.length - 1);
      }
      if (`${CONTEXT_BASE_URL}${itemRouteWithoutHash}` !== currentPathName) {
        await actions.navigate(`${CONTEXT_BASE_URL}${itemRouteWithoutHash}${hash}`);
      } else {
        window.dispatchEvent(new HashChangeEvent('hashchange'));
      }
      window.location.hash = hash;
    } else {
      await actions.navigate(`${CONTEXT_BASE_URL}${item.route}`);
      if (!hash || (currentRoute && currentRoute !== hash)) {
        window.dispatchEvent(new HashChangeEvent('hashchange'));
      }
    }
    setError?.(null);
    closeSubMenu();
  }

  function closeSubMenu() {
    setOpenMenu({
      close: openMenu.open,
      open: ''
    });
  }

  function openSubMenu(label: string) {
    setOpenMenu({
      close: openMenu.open,
      open: label === openMenu.open ? '' : label
    });
  }

  function menuOnBlur() {
    timeout = setTimeout(() => {
      closeSubMenu();
    });
  }

  // If a child receives focus, do not close the popover.
  function menuItemOFocus(label: string) {
    if (label !== openMenu.open) {
      closeSubMenu();
    }
    clearTimeout(timeout);
  }

  useEffect(() => {
    function handleKeyDown({ code }) {
      if (code === 'Escape') {
        closeSubMenu();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  function renderDropdown(item: NavManagerMenuItem) {
    const selected = containsCurrentRoute(item, currentRoute);

    return (
      <>
        <button
          data-testid={item.description}
          className={`
            ${selected ? 'selected' : ''}
            ${isMediumScreen ? 'lessPadding' : 'normalPadding'}
          `}
          onClick={() => openSubMenu(item.label)}
          aria-expanded={openMenu.open === item.label ? true : false}
        >
          <span>
            {item.label}
            <ChevronDownIcon className={selected ? 'primarySvg' : 'grey30Svg'} size="sm" />
          </span>
        </button>

        <SubMenuWrapper onMouseDown={e => e.preventDefault()}>
          <H2>{item.label}</H2>
          <SubMenu>
            {item.children?.map((child, index) => {
              let route = child.route;
              let hash;
              if (route && route.indexOf('/#') >= 0) {
                route = route.replace('/#', '#');
                hash = route.slice(route.indexOf('#'));
              }

              return (
                <li key={index}>
                  <a
                    href={`${CONTEXT_BASE_URL}${route}`}
                    className={containsCurrentRoute(child, currentRoute) ? 'selected' : ''}
                    data-testid={`${child.description?.toLowerCase().replace(/ /g, '-')}`}
                    onClick={e => navigateToItem(e, child, hash)}
                  >
                    {child.label}
                  </a>
                </li>
              );
            })}
          </SubMenu>
        </SubMenuWrapper>
      </>
    );
  }

  const merchantAdminMiniApp = useMemo(() => {
    // className of merchantSelectorWrapper according to single/multi facilites and screen width
    return merchantAdminAppConfig && merchantsData ? (
      <MerchantSelectorWrapper className={`${merchantsData.length > 1 ? (isMediumScreen ? 'lessWidth' : 'normalWidth') : ''}`}>
        <EmbeddedMiniApp
          appMetaData={merchantAdminAppConfig}
          appConfig={{
            view: 'desktop',
            initialMerchantsData: merchantsData
          }}
        />
      </MerchantSelectorWrapper>
    ) : (
      <></>
    );
  }, [merchantsData, merchantAdminAppConfig, isMediumScreen]);

  return (
    <MenuWrapper data-testid={'MenuWrapper'}>
      <Menu data-testid="Menu" onBlur={menuOnBlur}>
        {menu.map((item, index) => (
          <MenuItem
            onFocus={() => menuItemOFocus(item.label)}
            data-testid={`MenuItem${index}`}
            key={index}
            className={`
                            ${openMenu.close === item.label ? 'close' : ''}
                            ${openMenu.open === item.label ? 'open' : ''}
                        `}
          >
            {item.label === 'Home' && item.icon === 'HomeIcon' ? (
              <a
                alt="NAB Hive Home"
                href={`${CONTEXT_BASE_URL}${item.route}`}
                data-testid="Home"
                className={`${containsCurrentRoute(item, currentRoute) ? 'selected' : ''} home`}
                onFocus={() => item.label !== openMenu.open && closeSubMenu()}
                onClick={e => navigateToItem(e, item, null)}
              >
                <span>
                  <HomeIcon size="md" />
                </span>
              </a>
            ) : (
              renderDropdown(item)
            )}
          </MenuItem>
        ))}
      </Menu>
      {merchantAdminMiniApp}
    </MenuWrapper>
  );
}

export default PollinateMenu;
