import React, { useContext, useEffect } from 'react';
import { MiniAppInstance, ReactSpaContext } from '@nab/x-spa-react';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import MiniAppError, { errorHeading } from '../../Error/MiniAppError';
import Error, { createError } from '../../Error/Error';
import { AppViewport } from '../../Spa/RootSpa/RootSpa.styles';
import constants, { CONTEXT_BASE_URL, errors, FEATURE_FLAGS } from '../../../utils/constants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { ShellContext } from '../../Spa/GlobalSpa/GlobalSpa';

interface UsersPermissionsAdminProps {
error?: string;
  setError: (value: string) => void;
}

export function UsersPermissionsAdmin({ error, setError }: UsersPermissionsAdminProps) {
  const { activeAppInstance, actions } = useContext(ReactSpaContext);
  const { flagValue: isUIMAvailable } = useFeatureFlag(FEATURE_FLAGS.MINIAPP_NABC_UIM, false); // set false as default
  const { updatePageTitle } = useContext(ShellContext);

  function setUAMRoute(route: string) {
    let pageTitle;
    switch (route) {
      case '/users/create':
        pageTitle = 'Create User';
        break;
      default:
        pageTitle = 'Users & Permissions';
    }
    updatePageTitle(pageTitle);
  }

  useEffect(() => {
    actions.addEventListener(constants.UAM_MINIAPP_NAVIGATED, setUAMRoute);

    return () => {
      actions.removeEventListener(constants.UAM_MINIAPP_NAVIGATED, setUAMRoute);
    }

  }, [activeAppInstance])

  useEffect(() => {
    // Note: Feature flag - do not show the page if our
    if (!isUIMAvailable) {
      setError(errors.PAGE_NOT_FOUND);
    }
  }, [isUIMAvailable]);

  function renderMiniapp() {
    if (error) {
      return <Error error={createError(error, errorHeading(activeAppInstance?.id))} setError={setError} />;
    }

    activeAppInstance.appConfig = {
      ...activeAppInstance.appConfig,
      routingBasePath: CONTEXT_BASE_URL
    };

    return <MiniAppInstance appInstance={activeAppInstance} loadingSlot={MiniAppLoading} errorSlot={MiniAppError} />;
  }

  return <AppViewport id="appViewPort">{renderMiniapp()}</AppViewport>;
}

export default UsersPermissionsAdmin;
