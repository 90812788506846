import React, { useEffect, useContext, useMemo, useState, useCallback } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GlobalStyle, Theme } from '@nab/nui-react';
import { EmbeddedMiniApp, SpaProps, ReactSpaContext, ReactSpaProps } from '@nab/x-spa-react';
import Viewport from '../../common/Viewport/Viewport';
import MiniAppError from '../../Error/MiniAppError';
import constants, { analyticsEvents, CONTEXT_BASE_URL, FEATURE_FLAGS, LOGIN_URL, miniapps } from '../../../utils/constants';
import { findApp } from '../../../utils/appContext';
import { MerchantData } from '../RootSpa/RootSpa';
import { getBearerToken, getFacilityId, saveFacilityId } from '../../../utils/session';
import { pushAnalyticsEvent } from '../../../utils/analytics';
import useLaunchDarkly from '../../../hooks/useLaunchDarkly';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import Footer from '../../Footer/Footer';
import { HeaderWrapper } from '../RootSpa/RootSpa.styles';
import Header from '../../Header/Header';

const GlobalSpa: React.FC<SpaProps> = ({ activeAppInstance, initError, routeError }) => {
  const { actions, context } = useContext(ReactSpaContext) as ReactSpaProps;
  const [allMerchantsData, setAllMerchantsData] = useState<MerchantData[]>(null);
  const [selectedMerchantFacilityId, setSelectedMerchantFacilityId] = useState(null);
  const [facilityNotFoundFlag, setFacilityNotFoundFlag] = useState(false);
  const [currentMerchantRoute, setCurrentMerchantRoute] = useState(null);
  const bearerToken = getBearerToken();
  const [currentRoute, setCurrentRoute] = useState(null);
  const [pageTitle, setPageTitle] = useState(`NAB Hive`);
  const [featureFlags, setFeatureFlags] = useState(null);
  const { launchDarklyIsReady, launchDarklyError, launchDarklyFlags } = useLaunchDarkly();

  const setMerchantFacilitiesData = async data => {
    setAllMerchantsData(data);
    pushAnalyticsEvent(analyticsEvents.MINIAPP_LOADED, miniapps.MERCHANT_MENU.route);
    // if user logs in with no 'facilityId' cookie or single facility
    if (!getFacilityId() || data.length === 1) {
      setSelectedMerchantFacilityId(`${data[0].merchantId}`);
      saveFacilityId(data[0].merchantId);
      await actions.dispatchEvent(constants.SHELL_MERCHANT_ID_CHANGED, data[0].merchantId);
    }
    // checks if merchantId exists in data
    else if (!data.some(({ merchantId }) => merchantId === getFacilityId())) {
      saveFacilityId(data[0].merchantId);
      await actions.dispatchEvent(constants.SHELL_MERCHANT_ID_CHANGED, data[0].merchantId);
    }
  };

  const setSelectedMerchantFacility = useCallback(
    async facilityId => {
      // Notes: when user selects merchant facility manually from dropdown
      if (!facilityNotFoundFlag && getFacilityId() && getFacilityId() !== facilityId) {
        await actions.dispatchEvent(constants.SHELL_MERCHANT_ID_CHANGED, facilityId);
        saveFacilityId(facilityId);
        setSelectedMerchantFacilityId(`${facilityId}`);
        setCurrentMerchantRoute(window.location.pathname + window.location.hash);
      } else if (getFacilityId() && facilityId) {
        // Note: when user logs in with a 'facilityId' cookie
        setSelectedMerchantFacilityId(`${facilityId}`);
        await actions.dispatchEvent(constants.SHELL_MERCHANT_ID_CHANGED, facilityId);
      }
    },
    [selectedMerchantFacilityId]
  );

  useEffect(() => {
    if (currentMerchantRoute && selectedMerchantFacilityId) {
      const acceptedRoutes = [miniapps.DX_FORM.route.business, miniapps.DX_FORM.route.store, miniapps.DX_FORM.route.details];
      if (acceptedRoutes.includes(window.location.pathname)) {
        actions.navigate(miniapps.WORKFLOW_MANAGEMENT.route);
      }
    }
  }, [currentMerchantRoute, selectedMerchantFacilityId]);

  useEffect(() => {
    if (
      window.location.pathname.indexOf(CONTEXT_BASE_URL) !== 0 &&
      window.location.pathname !== '/' &&
      window.location.pathname.indexOf(LOGIN_URL) !== 0
    ) {
      window.location.pathname = CONTEXT_BASE_URL + window.location.pathname;
      localStorage.setItem('redirectionFrom', window.location.pathname);
    }

    // checks if facilityId exists already and is not encrypted, then encrypts if so
    const facilityId = localStorage.getItem(constants.FACILITY_ID);
    if (facilityId && (/^([0-9]{7})$/.test(facilityId))) {
      saveFacilityId(facilityId);
    }
  }, []);

  useEffect(() => {
    // Get merchant data from miniapp
    actions.addEventListener(constants.MERCHANT_MINIAPP_LOADED, setMerchantFacilitiesData);
    actions.addEventListener(constants.MERCHANT_MINIAPP_FACILITY_SELECTED, setSelectedMerchantFacility);
    actions.addEventListener(constants.FACILITY_NOT_FOUND, setFacilityNotFoundFlag);

    return () => {
      actions.removeEventListener(constants.MERCHANT_MINIAPP_LOADED, setMerchantFacilitiesData);
      actions.removeEventListener(constants.MERCHANT_MINIAPP_FACILITY_SELECTED, setSelectedMerchantFacility);
      actions.removeEventListener(constants.FACILITY_NOT_FOUND, setFacilityNotFoundFlag);
    };
  }, []);

  const getSpaContent = useMemo(() => {
    if (launchDarklyError) {
      return (
        <>
          <HeaderWrapper>
            <Header showPollinateMenu={false} />
          </HeaderWrapper>
          <MiniAppError app={activeAppInstance?.app} initError={initError} routeError={routeError} />
          <Footer />
        </>
        )
    }
    if (!launchDarklyIsReady) {
      return <MiniAppLoading />;
    } else {
      if (initError || routeError) {
        return (
          <>
            <MiniAppError initError={initError} routeError={routeError} />
          </>
        );
      }
      if (launchDarklyIsReady && launchDarklyFlags) {
        if (launchDarklyFlags[FEATURE_FLAGS.ENSIGHTEN_SCRIPT_KILL_SWITCH]) {
          document.getElementById('bootstrapScript').remove();
        }
      }
      return <Viewport appInstance={activeAppInstance} />;
    }
  }, [activeAppInstance, initError, routeError, launchDarklyIsReady, launchDarklyError, launchDarklyFlags]);

  const merchantAdminAppConfig = useMemo(() => {
    return context ? findApp(context, miniapps.MERCHANT_MENU.name) : null;
  }, [context]);

  const merchantAdminMiniApp = useMemo(() => {
    return merchantAdminAppConfig ? (
      <EmbeddedMiniApp
        appMetaData={merchantAdminAppConfig}
        appConfig={{
          view: 'data-loader'
        }}
      />
    ) : (
      <></>
    );
  }, [merchantAdminAppConfig]);

  const updatePageTitle = title => {
    setPageTitle(`NAB Hive ${title ? '- ' + title : ''}`);
  };

  useEffect(() => {
    setFeatureFlags(launchDarklyFlags);
  }, [launchDarklyFlags]);

  return (
    <>
      <GlobalStyle normalize borderBox fontImpact />
      <HelmetProvider>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </HelmetProvider>
      <Theme>
        <ShellContext.Provider
          value={{
            merchantAdminAppConfig,
            merchantsData: allMerchantsData,
            selectedMerchantId: selectedMerchantFacilityId, // Notes: this will get set when user selects an facility option from drop-down
            currentRoute,
            updateCurrentRoute: setCurrentRoute,
            updatePageTitle,
            featureFlags
          }}
        >
          {getSpaContent}
        </ShellContext.Provider>
        {bearerToken && merchantAdminMiniApp}
      </Theme>
    </>
  );
};

export const useCurrentRoute = () => {
  const { currentRoute, updateCurrentRoute } = useContext(ShellContext);
  return { currentRoute, setCurrentRoute: updateCurrentRoute };
};

export const ShellContext = React.createContext({
  merchantAdminAppConfig: null, // Notes: we need to have this as part of the context so config can be retrieved in components nested in child contexts
  merchantsData: null,
  selectedMerchantId: null,
  currentRoute: null,
  updateCurrentRoute: null,
  updatePageTitle: null,
  featureFlags: null
});

export default GlobalSpa;
