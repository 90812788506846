// src/components/AppViewport.tsx
import styled from 'styled-components';
import { media } from '@nab/nui-react';
import { styling } from '../../../utils/constants';

const timeoutModalSm = media.sm`
  top: 20%;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
`;

const HeaderWrapperLg = media.lg`
  position: unset;
`;

export const SpaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  &.isMobileMenuOpen {
    overflow: hidden;
  }
`;

export const HeaderWrapper = styled.div`
  z-index: 999;
  position: sticky;
  top: 0;

  ${HeaderWrapperLg}
  &.isHidden {
    display: none;
  }
`;

export const AppViewport = styled.div`
  display: flex;
  position: relative;
  flex: 1;

  #confirmModal {
    ${media.sm`
      max-height: 320px;
    `}

    min-height: auto;
    max-height: auto;
  }

  #timeoutModal {
    margin-top: 104px;

    & > div {
      height: auto;
    }

    ${timeoutModalSm}
  }
  > div {
    width: 100%;
  }

  // This ensures apps are no longer displayed once they start shutting down
  > [aria-hidden='true'] {
    .x-mini-app-host-container {
      display: none;
    }
  }

  & > .x-mini-app-host-container {
    min-height: calc(100vh - ${styling.MOBILE_FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT});
    min-height: calc(calc(var(--vh, 1vh) * 100) - ${styling.MOBILE_FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT});

    ${media.sm`
      min-height: calc(100vh - ${styling.FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT} - ${styling.MENU_BAR_HEIGHT});
      min-height: calc(calc(var(--vh, 1vh) * 100) - ${styling.FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT} - ${styling.MENU_BAR_HEIGHT});
    `}

    grid-row-start: 1;
    grid-column-start: 1;
  }

  .x-mini-app-host-container.hidden {
    padding: 0;
    display: none;
  }
`;
