import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Modal, ClockIcon, Paragraph, Row, Col, Container, Button, media } from '@nab/nui-react';
import { ReactSpaContext } from '@nab/x-spa-react';
import { logout, navigateToLogin } from '../../../utils/session';
import constants from '../../../utils/constants';

const ButtonSm = media.sm`
    width: auto;
`;

const ModalContentWrapper = styled.div`
  margin-top: 24px;

  p {
    margin-bottom: 32px;
  }

  button {
    width: 100%;
    min-width: 169px;

    &#timeoutLogoutButton {
      float: right;
    }

    &#continueButton {
      float: left;
    }

    ${ButtonSm}
  }
`;

interface ModalProps {
  setError?: (value: string) => void;
}

export function TimeoutModal({ setError }: ModalProps) {
  const [isClosing, setIsClosing] = useState(false);
  const { actions } = useContext(ReactSpaContext);

  const modalHasClosed = () => {
    setIsClosing(false);
    window.location.reload();
  };

  const logoutClicked = async () => {
    await logout();
    await actions.dispatchEvent(constants.POLLINATE_MINIAPP_LOGOUT);
    navigateToLogin();
  };

  return (
    <Modal
      rootNode={document.getElementById('appViewPort') || document.body}
      id="timeoutModal"
      label="timeoutModal"
      hasClose={true}
      title="Your session will end soon"
      icon={ClockIcon}
      alignment="middle"
      beginClose={isClosing}
      titleShouldResize
      size="small"
      onClose={modalHasClosed}
    >
      <ModalContentWrapper>
        <Container id="modalContainer">
          <Row bottomSpacing="sm">
            <Col>
              <Paragraph>You will be logged out of NAB Hive {<br />} due to inactivity.</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6" order="last" smOrder="first">
              <Button
                type="button"
                variant="secondary"
                label="Logout"
                id="timeoutLogoutButton"
                onClick={() => {
                  logoutClicked();
                }}
              />
            </Col>
            <Col xs="12" sm="6" order="first" smOrder="last" bottomSpacing="xs">
              <Button type="button" variant="primary" label="Continue session" id="continueButton" onClick={() => setIsClosing(true)} />
            </Col>
          </Row>
        </Container>
      </ModalContentWrapper>
    </Modal>
  );
}

export default TimeoutModal;
