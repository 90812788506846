import { useState, useEffect } from 'react';
import { getBearerToken } from '../utils/session';

interface IState {
  isAuthenticated: boolean;
  loading: boolean;
}

interface IUseAuth {
  redirectTo?: string | undefined;
  shouldRedirect?: boolean;
}

export const useAuth = ({ redirectTo, shouldRedirect }: IUseAuth = { redirectTo: undefined, shouldRedirect: true }): IState => {
  const [state, setState] = useState<IState>({
    isAuthenticated: false,
    loading: true
  });

  useEffect(() => {
    const checkToken = async () => {
      const bearerToken = await getBearerToken();

      if (bearerToken) {
        setState({
          isAuthenticated: true,
          loading: false
        });

        return;
      }

      if (window.location.pathname.indexOf('/login') === -1 && shouldRedirect) {
        // Note: store the original path before user is being redirected to login
        localStorage.setItem('redirectionFrom', window.location.pathname);

        // This has to be assign to reload the app after login again.
        window.location.assign(redirectTo || '/login');
      }

      setState({
        isAuthenticated: false,
        loading: false
      });
    };

    checkToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectTo, shouldRedirect, window.location.pathname]);

  return { ...state };
};
