import React, { useEffect, useState } from 'react';
import { H1, InternalLinkItem, Link, ListGroup, ListGroupItem } from '@nab/nui-react';
import { LoginSelectorWrapper, LoginSelectorButtons, InnerLoginSelectorWrapper } from './LoginSelector.styles';
import LoginMethodModal from '../Spa/Login/LoginMethodModal/LoginMethodModal';
import constants, { analyticsEvents, miniapps } from '../../utils/constants';
import { getPreferredLoginMethod } from '../../utils/session';
import MiniAppLoading from '../MiniAppLoading/MiniAppLoading';
import { pushAnalyticsEvent } from '../../utils/analytics';

const LoginSelector = ({ isIBLoginEnabled = false }) => {
  const [openModal, setOpenModal] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [userLoginMethodChoice, setUserLoginMethodChoice] = useState(null);
  const method = getPreferredLoginMethod();

  useEffect(() => {
    if (!isIBLoginEnabled) {
      // Notes: IB login is disabled hence we redirect user to nabc page by default
      setRedirecting(true);
      window.location.pathname = miniapps.LOGIN.NABC.route;
    } else {
      // Notes: IB login is enabled. Now we check for the saved "preferred" login method in local storage
      switch (method) {
        case constants.LOGIN_METHOD_IB:
          setRedirecting(true);
          window.location.pathname = miniapps.LOGIN.IB.route;
          break;
        case constants.LOGIN_METHOD_NABC:
          setRedirecting(true);
          window.location.pathname = miniapps.LOGIN.NABC.route;
          break;
        default:
          break;
      }
    }
  }, [method, redirecting, isIBLoginEnabled]);

  useEffect(() => {
    if (!redirecting) {
      pushAnalyticsEvent(analyticsEvents.LOGIN_PAGE_VIEW);
    }
  }, [redirecting]);

  useEffect(() => {
    if (!redirecting) {
      userLoginMethodChoice && pushAnalyticsEvent(analyticsEvents.SELECT_LOGIN_ID_USER_MOMENT, userLoginMethodChoice);
    }
  }, [redirecting, userLoginMethodChoice]);

  function openLoginMethodModal(e: Event) {
    e.preventDefault();
    setOpenModal(true);
    pushAnalyticsEvent(analyticsEvents.UNKNOWN_LOGIN_METHOD_USER_MOMENT);
  }

  return (
    <>
      {redirecting ? (
        <MiniAppLoading />
      ) : (
        <LoginSelectorWrapper>
          <InnerLoginSelectorWrapper>
            <H1 variant="impact">WELCOME TO NAB HIVE</H1>
            <p id="loginSelectorDescription">You can log in using your NAB Connect or Internet Banking details.</p>
            <LoginSelectorButtons>
              <ListGroup>
                <ListGroupItem>
                  <InternalLinkItem
                    label="Log in with NAB Connect"
                    caption="The NAB Connect User ID is a 10-digit number that starts with 620 or is your Preferred User ID if you set one."
                    data-testid="nabcLoginLink"
                    link={miniapps.LOGIN.NABC.route}
                    onClick={() => {
                      setUserLoginMethodChoice('nab-connect');
                    }}
                  />
                </ListGroupItem>
                <ListGroupItem>
                  <InternalLinkItem
                    label="Log in with Internet Banking"
                    caption="Use the NAB ID you use to manage your business banking in NAB Internet Banking or the NAB Mobile Banking app."
                    data-testid="ibLoginLink"
                    link={miniapps.LOGIN.IB.route}
                    onClick={() => {
                      setUserLoginMethodChoice('nab-internet-banking');
                    }}
                  />
                </ListGroupItem>
              </ListGroup>
            </LoginSelectorButtons>
            <Link href="" onClick={(e: Event) => openLoginMethodModal(e)}>
              Not sure which one to choose?
            </Link>
            {openModal && <LoginMethodModal setOpenModal={setOpenModal} />}
          </InnerLoginSelectorWrapper>
        </LoginSelectorWrapper>
      )}
    </>
  );
};

export default LoginSelector;
