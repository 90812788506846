import styled from 'styled-components';

export const MerchantSelectorWrapper = styled.div`
  margin-left: auto;
  margin-right: 32px;
  align-self: flex-start;

  &.lessWidth {
    width: 202px;
  }
  &.normalWidth {
    width: 272px;
  }
`;

export const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #f2f4f6;
  align-items: center;
  padding: 8px 0 0 32px;
  height: 56px;
  box-shadow: 0 1px 0 0 #666666;
`;

export const Menu = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
`;

export const MenuItem = styled.li`
  display: flex;
  position: relative;

  .primarySvg path {
    fill: ${({ theme }) => theme.color.primary};
  }

  .grey30Svg path {
    fill: ${({ theme }) => theme.color.grey30};
  }

  & > a {
    padding: 8px;

    span {
      width: 32px;
      height: 32px;
      transition: transform 0.2s ease-out;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  & > button {
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    color: ${({ theme }) => theme.color.grey30};

    &.lessPadding {
      padding: 0 42px 0 12px;
    }
    &.normalPadding {
      padding: 0 51px 0 22px;
    }

    span {
      position: relative;
      display: block;
      transition: transform 0.2s ease-out;
      white-space: nowrap;

      svg {
        transition: transform 0.1s cubic-bezier(0.15, -0.08, 0.74, 0.05);
        position: absolute;
        top: -3px;
        right: -32px;
      }
    }
  }

  & > a, & > button {
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
    overflow: hidden;

    span {
      position: relative;
      display: block;
      transition: transform 0.2s ease-out;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
      height: 8px;
      background-color: ${({ theme }) => theme.color.black};
      transition: transform 0.2s ease-in;
    }

    &:hover, &:active {
      text-decoration: underline;
      color: ${({ theme }) => theme.color.grey30} !important;

      svg path {
        fill: ${({ theme }) => theme.color.grey30} !important;
      }

      &:after {
        background-color: ${({ theme }) => theme.color.black} !important;
        transform: translateY(-4px);
      }
    }

    &:active {
      background-color: #666666;

      span {
        filter: brightness(0) invert(1);
        transform: translateY(2px);
      }
    }
  }

  a:focus, button:focus {
    outline: 0;
    box-shadow: inset 0 0 0 3px #999999
  }

  & > .selected:after {
    transform translateY(-4px);
    margin: 0 2px;
  }

  & > button.selected {
    color: ${({ theme }) => theme.color.primary};

    &:after {
      background-color: ${({ theme }) => theme.color.primary};
    }
  }

  &.open {
    & > div {
      visibility: visible;
      animation: slideDown 0.6s ease forwards;
    }

    svg {
      transform: rotateZ(180deg);
    }
  }

  &.close {
    & > div {
      visibility: visible;
      animation: slideUp 0.4s ease forwards;
    }

    svg {
      transform: rotateZ(0deg);
    }
  }
`;

export const SubMenuWrapper = styled.div`
  z-index: -1;
  position: absolute;
  bottom: 12px;
  left: -20px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.4);
  border: 1px solid ${({ theme }) => theme.color.black};
  border-radius: 4px;
  padding: 24px;
  opacity: 0;
  visibility: hidden;

  & > h2 {
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    font-size: ${({ theme }) => theme.font.size.xxs};
    margin: 0 0 8px;
    text-transform: uppercase;
    line-height: 18px;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    50% {
      transform: translateY(calc(100% + 30px));
      opacity: 0.9;
    }
    100% {
      transform: translateY(calc(100% + 20px));
      opacity: 1;
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(calc(100% + 20px));
      opacity: 1;
    }
    to {
      transform: translateY(0);
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const SubMenu = styled.ul`
  padding: 0;

  & > li {
    list-style: none;
    min-width: 200px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey90};
    padding: 0;

    &:first-of-type {
      border-top: 1px solid ${({ theme }) => theme.color.grey90};
    }

    a {
      cursor: pointer;
      display: block;
      text-decoration: none;
      color: ${({ theme }) => theme.color.grey10};
      line-height: 48px;
      padding: 0 16px 0 12px;

      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.color.grey10};
        background-color: ${({ theme }) => theme.color.grey90};
      }

      &:active {
        text-decoration: underline;
        background-color: #666666;
        color: ${({ theme }) => theme.color.white};
      }
    }

    & > .selected {
      background-color: ${({ theme }) => theme.color.black};
      color: ${({ theme }) => theme.color.white};
    }
  }
`;
