import styled from 'styled-components';
import { media } from '@nab/nui-react';

const LinkedListLg = media.lg`
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  flex-grow: 0;

  li:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0px;
  }
`;

const LinkedListMd = media.md`
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  flex-grow: 0;

  li:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0px;
  }
`;

const LinkedListSm = media.sm`
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  flex-grow: 0;

  li:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0px;
  }
`;

const FooterWrapperLg = media.lg`
  padding-left: 80px;
  padding-right: 80px;
`;

const NavLg = media.lg`
  flex-direction: row;
  margin-left: 0px;
`;

const NavMd = media.md`
  flex-direction: row;
  margin-left: 8px;
`;

const NavSm = media.sm`
  flex-direction: row;
  margin-left: 8px;
`;

export const FooterWrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 144px;
  padding-left: 16px;
  padding-right: 16px;

  & > nav {
    display: flex;
    flex-direction: row;
    margin-left: 8px;

    ${NavSm}
    ${NavMd}
    ${NavLg}
  }

  ${FooterWrapperLg}
`;

export const LinkList = styled.ul`
  color: #f2f2f2;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  list-style: none;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  margin: 0;
  flex-grow: 1;

  li {
    margin-right: 0px;
    margin-bottom: 16px;
  }

  ${LinkedListSm}
  ${LinkedListMd}
  ${LinkedListLg}
`;

export const SupportLinkList = styled.ul`
  color: #f2f2f2;
  padding-left: 0px;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0px;

  li {
    margin-right: 0px;
    margin-bottom: 8px;
  }
`;

export const CopyrightNabContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
  & > p {
    font-size: ${({ theme }) => theme.font.size.xxs};
  }
`;

export const ScreenReaderSpan = styled.span`
  position: absolute;
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
`;
