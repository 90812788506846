import styled from 'styled-components';
import { media } from '@nab/nui-react';
import { styling } from '../../../utils/constants';

const LoginFormWrapperLg = media.lg`
  flex: 0 0 60%;
  max-width: 60%;
  display: block;
  padding: 40px 64px 64px 64px;
`;

const LoginFormWrapperXl = media.xl`
  flex: 0 0 42%;
  max-width: 42%;
  display: block;
`;

const LoginBackgroundImageLg = media.lg`
  flex: 0 0 40%;
  max-width: 40%;
`;

const LoginBackgroundImageXl = media.xl`
  flex: 0 0 58%;
  max-width: 58%;
`;

const InnerLoginFormWrapperLg = media.lg`
 padding: 0px;
`;

export const LoginFormWrapper = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - ${styling.FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT});
  min-height: calc(calc(var(--vh, 1vh) * 100) - ${styling.FOOTER_HEIGHT} - ${styling.HEADER_HEIGHT});
  grid-row-start: 1;
  grid-column-start: 1;
  flex-flow: column;
  align-items: center;
  padding-block: 40px 96px;
  padding-inline: 24px;

  ${LoginFormWrapperLg}

  ${LoginFormWrapperXl}

  .x-mini-app-host-container, > [x-mini-app-host] {
    max-width: 480px;
    margin: 0;
    background-clip: content-box;
    background-color: ${({ theme }) => theme.color.white};
  }

  [data-testid='nabcFormWrapper'] > .x-mini-app-host-container {
    padding: 0px 32px;
  }
`;

export const InnerLoginFormWrapper = styled.div`
  padding: 32px 0px;
  background-color: ${({ theme }) => theme.color.white};
  ${InnerLoginFormWrapperLg}

  // For the login miniapp error page
  [data-testid="miniappError"] {
    position: absolute;
    top: 0;
    left: 0;
  }

  & > h1 {
    padding-left: ${styling.LOGIN_MINIAPP_LEFT_PADDING};
    padding-right: ${styling.LOGIN_MINIAPP_LEFT_PADDING};
    margin-bottom: 1rem;
  }

  & > p {
    margin-bottom: 0.5rem;
    width: 100%;
    align-self: center;
    font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-left: ${styling.LOGIN_MINIAPP_LEFT_PADDING};
    padding-right: ${styling.LOGIN_MINIAPP_LEFT_PADDING};
  }

  .nabcLeftPadding {
    padding-left: calc(${styling.LOGIN_MINIAPP_LEFT_PADDING} + 5px);
  }
`;

export const LoginBackgroundImage = styled.img`
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 45% 55%;
  z-index: -1;
  flex: 0 0 0%;
  max-width: 100%;

  ${LoginBackgroundImageLg}

  ${LoginBackgroundImageXl}
`;

export const AltLinkWrapper = styled.div`
  padding-left: ${styling.LOGIN_MINIAPP_LEFT_PADDING};
  padding-top: 14px;
  display: inline-flex;
  & > a,
  p {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  & > a {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;
