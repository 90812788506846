import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Modal, WarningFillIcon, Paragraph, Row, Col, Container, Button, media } from '@nab/nui-react';
import { ReactSpaContext } from '@nab/x-spa-react';
import constants from '../../../utils/constants';

const ButtonSm = media.sm`
  width: auto;
`;

const ModalContentWrapper = styled.div`
  height: 100%;
  padding-top: 16px;

  & > div {
    height: 100%;
  }

  [data-testid='modalContainer'] {
    display: grid;
    height: auto;
  }

  button {
    width: 100%;
    align-self: end;

    &[data-testid='cancelButton'] {
      float: right;
    }

    &[data-testid='leaveButton'] {
      float: left;
    }

    ${ButtonSm}
  }
`;

interface ConfirmModalProps {
  setOpenModal: (value: boolean) => void;
}

export function ConfirmModal({ setOpenModal }: ConfirmModalProps) {
  const [isClosing, setIsClosing] = useState(false);
  const [isSelectedMerchantCancelled, setIsSelectedMerchantCancelled] = useState(true);
  const { actions } = useContext(ReactSpaContext);

  const modalHasClosed = () => {
    if (isSelectedMerchantCancelled) {
      actions.dispatchEvent(constants.CANCEL_SELECTED_MERCHANT);
    }
    setIsClosing(false);
    setOpenModal(false);
  };

  return (
    <Modal
      rootNode={document.getElementById('appViewPort') || document.body}
      id="confirmModal"
      label="confirmLabel"
      hasClose={true}
      title="Cancel request?"
      titleVariant="h2"
      icon={WarningFillIcon}
      iconColor="amber"
      alignment="middle"
      beginClose={isClosing}
      titleShouldResize
      size="small"
      onClose={modalHasClosed}
    >
      <ModalContentWrapper>
        <Container data-testid="modalContainer">
          <Row bottomSpacing="sm">
            <Col>
              <Paragraph>None of your changes will be saved.</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6" order="last" smOrder="first">
              <Button
                type="button"
                variant="secondary"
                label="No, keep it"
                data-testid="cancelButton"
                onClick={() => {
                  setIsClosing(true);
                }}
              />
            </Col>
            <Col xs="12" sm="6" order="first" smOrder="last" bottomSpacing="sm">
              <Button
                type="button"
                variant="primary"
                label="Yes, cancel"
                data-testid="leaveButton"
                onClick={() => {
                  actions.dispatchEvent(constants.CONFIRM_SELECTED_MERCHANT);
                  setIsClosing(true);
                  setIsSelectedMerchantCancelled(false);
                }}
              />
            </Col>
          </Row>
        </Container>
      </ModalContentWrapper>
    </Modal>
  );
}

export default ConfirmModal;
