import { Paragraph, Link, ChatIcon } from '@nab/nui-react';
import { FooterWrapper, CopyrightNabContainer, LinkList, ScreenReaderSpan, SupportLinkList } from './Footer.styles';

export function Footer(props) {
  return (
    <FooterWrapper>
      <CopyrightNabContainer>
        <Paragraph id="NAB_text" isOnDarkBg>
          © National Australia Bank Limited ABN 12 004 044 937 AFSL and Australian Credit Licence 230686
        </Paragraph>
      </CopyrightNabContainer>
      <nav aria-label="footer">
        <SupportLinkList>
          <li>
            <Link
              id="contactUsLink"
              isOnDarkBg
              icon={ChatIcon}
              href="https://www.nab.com.au/contact-us/business/merchant-solutions-support"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
              <ScreenReaderSpan>, opens in new window</ScreenReaderSpan>
            </Link>
          </li>
        </SupportLinkList>
        <LinkList>
          <li>
            <Link id="feedbackLink" isOnDarkBg href="https://www.nab.com.au/contact-us/feedback" target="_blank" rel="noopener noreferrer">
              Feedback
              <ScreenReaderSpan>, opens in new window</ScreenReaderSpan>
            </Link>
          </li>
          <li>
            <Link id="privacyLink" isOnDarkBg href="https://www.nab.com.au/common/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy
              <ScreenReaderSpan>, opens in new window</ScreenReaderSpan>
            </Link>
          </li>
          <li>
            <Link id="websiteTermsLink" isOnDarkBg href="https://www.nab.com.au/common/website-disclaimer" target="_blank" rel="noopener noreferrer">
              Website terms
              <ScreenReaderSpan>, opens in new window</ScreenReaderSpan>
            </Link>
          </li>
          <li>
            <Link
              id="sitemapLink"
              isOnDarkBg
              href="https://www.nab.com.au/about-us/using-this-site/sitemap"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sitemap
              <ScreenReaderSpan>, opens in new window</ScreenReaderSpan>
            </Link>
          </li>
        </LinkList>
      </nav>
    </FooterWrapper>
  );
}

export default Footer;
