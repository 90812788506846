import styled from 'styled-components';
import { styling } from '../../../utils/constants';

export const MobileMenuWrapper = styled.div`
  transition: transform 0.5s ease-in-out;
  transition-delay: 0s;
  min-width: 100vw;
  height: calc(100vh - ${styling.HEADER_HEIGHT});
  height: calc(calc(var(--vh, 1vh) * 100) - ${styling.HEADER_HEIGHT});
  position: fixed;
  top: ${styling.HEADER_HEIGHT};
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.grey96};
  overflow-y: scroll;
  padding-bottom: 40px;

  &.noAnimation {
    transition: none !important;
  }
  &.close {
    transform: translateX(-100%);
    transition: transform 0.25s ease-in-out;
  }

  &.closeMainMenu {
    transform: translateX(-100%);
  }

  &.openSubMenu {
    transform: translateX(0);
  }

  &.closeSubMenu {
    transform: translateX(100%);
  }

  & > div {
    margin-left: 24px;
    margin-right: 24px;
  }

  & > button {
    text-decoration: none;
    padding-left: 8px;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    font-size: 1.125rem;
    margin-top: 8px;
  }

  & > #merchantDataLabel {
    position: unset;
    margin-top: 16px;
  }
`;

export const LinksWrapper = styled.div`
  bottom: 104px;
  width: calc(100% - 24px - 24px);

  .selectedLink {
    box-shadow: 0 0 0 3px rgb(194 0 0 / 65%);
    outline-color: transparent;
    outline-style: solid;
  }

  // Moves links upwards
  &.linksOnly {
    bottom: auto;
  }

  & > ul {
    margin: 0;

    & > li {
      margin-bottom: 8px;

      &:hover {
        background-color: ${({ theme }) => theme.color.grey90};
        text-decoration: underline;
        color: ${({ theme }) => theme.color.black};
      }

      & > a {
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        color: ${({ theme }) => theme.color.black};

        & > span > svg {
          color: ${({ theme }) => theme.color.black} !important;
        }
        &:focus {
          color: ${({ theme }) => theme.color.black};
        }
      }

      & > svg {
        color: ${({ theme }) => theme.color.black};
      }
    }
  }
`;

export const NavigationWrapper = styled.div`
  padding-top 24px;

  &.showMenu {
    min-height: calc(100% - ${styling.HEADER_HEIGHT} - 160px);
    padding-bottom: 24px;
  }

  &.loggedOut {
    min-height: calc(100% - ${styling.HEADER_HEIGHT});
    padding-bottom: 24px;
  }

  & > h2 {
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    font-size: ${({ theme }) => theme.font.size.sm};
    line-height: 1.15;
  }

  & > ul#MenuList {
    margin-top 0;

    & li > div > a:hover {
      border-left: 4px solid ${({ theme }) => theme.color.black};
    }
  }

  // Removing arrow from home navigation
  [data-testid="Home"] > span {
    display: none;
  }

  // States of the menu items
  & > ul > li > div, & > div {
    & > a {
      transition:
      box-shadow 200ms ease-in,
      background-color 200ms ease-in,
      color 200ms ease-in,
      fill 200ms ease-in,
      width 200ms ease-in-out !important;

      & > span > svg {
        color: ${({ theme }) => theme.color.black} !important;
      }

      &:hover {
          border-bottom: none !important;
          border-top: none !important;
      }

      &:focus {
        box-shadow: inset 0 0 0 3px ${({ theme }) => theme.color.grey30} !important;
        text-decoration: underline !important;
      }

      &:active {
        background-color: ${({ theme }) => theme.color.grey30} !important;
        border-left: none !important;

        & > div > div {
          color: ${({ theme }) => theme.color.white} !important;
        }

        & > span > svg {
          color: ${({ theme }) => theme.color.white} !important;
        }
      }
    }
  }

  & > ul > li.selected > div > a:active {
    border-left: 4px solid ${({ theme }) => theme.color.black} !important;
  }

  // For selected login and selected menu
  & > ul > li.selected > div, & > div {
    &:hover {
      & > a > div > div {
        color: ${({ theme }) => theme.color.black};
      }
    }

    & > a {
      border-left: 4px solid ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.primary};

      &:hover {
        border-left: 4px solid ${({ theme }) => theme.color.black};

        & > span > svg {
          color: ${({ theme }) => theme.color.black} !important;
        }
      }

      &:focus {
        color: ${({ theme }) => theme.color.black} !important;
        border: none;

        & > div > div {
          color: ${({ theme }) => theme.color.black} !important;
        }

        & > span > svg {
          color: ${({ theme }) => theme.color.black} !important;
        }
      }

      &:active {
        & > div > div {
          color: ${({ theme }) => theme.color.white} !important;
        }

        & > span > svg {
          color: ${({ theme }) => theme.color.white} !important;
        }
      }

      & > div > div {
        color: ${({ theme }) => theme.color.primary};

        & > p {
          font-weight: ${({ theme }) => theme.font.weight.bold};
        }
      }

      & > span > svg {
        color: ${({ theme }) => theme.color.primary} !important;
      }
    }
  }

  & > ul.children > li > div > a {
    & > div > div > p  {
      font-weight: ${({ theme }) => theme.font.weight.regular};
    }

    & > span {
      display: none;
    }
  }

  // Selected state of the sub menus
  & > ul > li.selectedBlack  {
    background-color: ${({ theme }) => theme.color.black};

    & > div > a > div > div {
      color: ${({ theme }) => theme.color.white} ;
    }

    & > div:hover {
      & > a > div > div {
        color: ${({ theme }) => theme.color.black};
      }
    }
  }
`;
