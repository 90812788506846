import styled from 'styled-components';
import { media } from '@nab/nui-react';

const LoginBackgroundImageLg = media.lg`
  flex: 0 0 40%;
  max-width: 40%;
`;

const LoginBackgroundImageXl = media.xl`
  flex: 0 0 58%;
  max-width: 58%;
`;

const InnerLoginSelectorWrapperLg = media.lg`
  margin: 5px;
`;

const LoginSelectorWrapperLg = media.lg`
  padding-right: 80px;
`;

export const InnerLoginSelectorWrapper = styled.div`
  margin: ${({ theme }) => theme.spaces.lg};
  ${InnerLoginSelectorWrapperLg};
`;

export const LoginSelectorWrapper = styled.div`
  max-width: 480px;
  margin: 0 0 32px 0;
  background-clip: content-box;
  background-color: ${({ theme }) => theme.color.white};
  padding-right: 0px;
  ${LoginSelectorWrapperLg};

  p {
    line-height: 24px;
  }
`;

export const LoginSelectorButtons = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const LoginBackgroundImage = styled.img`
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 45% 55%;
  z-index: -1;
  flex: 0 0 0%;
  max-width: 100%;

  ${LoginBackgroundImageLg}

  ${LoginBackgroundImageXl}
`;
