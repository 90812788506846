import React, { useContext, useEffect, useMemo } from 'react';
import { MiniAppInstance, ReactSpaContext } from '@nab/x-spa-react';
import MiniAppLoading from '../../MiniAppLoading/MiniAppLoading';
import MiniAppError, { errorHeading } from '../../Error/MiniAppError';
import Error, { createError } from '../../Error/Error';
import { AppViewport } from '../../Spa/RootSpa/RootSpa.styles';
import { pushAnalyticsEvent } from '../../../utils/analytics';
import constants, { analyticsEvents, miniapps } from '../../../utils/constants';
import { MerchantData } from '../../Spa/RootSpa/RootSpa';
import { errors, FEATURE_FLAGS } from '../../../utils/constants';
import { isIBUser } from '../../../utils/session';
import { ShellContext } from '../../Spa/GlobalSpa/GlobalSpa';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

interface WorkflowManagementProps {
  error?: string;
  setError: (value: string) => void;
  merchantData?: MerchantData;
}

export function WorkflowManagement({ error, setError }: WorkflowManagementProps) {
  const { activeAppInstance, actions } = useContext(ReactSpaContext);
  const { selectedMerchantId, merchantsData } = useContext(ShellContext);
  const { flagValue: isWorkflowAppAvailable } = useFeatureFlag(FEATURE_FLAGS.MINIAPP_WORKFLOW, false); // set true as default

  useEffect(() => {
    function wfMiniappLoaded() {
      pushAnalyticsEvent(analyticsEvents.MINIAPP_LOADED, miniapps.WORKFLOW_MANAGEMENT.route);
    }
    actions.addEventListener(constants.WORKFLOW_MANAGEMENT_MINIAPP_LOADED, wfMiniappLoaded);
    return () => {
      actions.removeEventListener(constants.WORKFLOW_MANAGEMENT_MINIAPP_LOADED, wfMiniappLoaded);
    };
  });

  useEffect(() => {
    if (!isWorkflowAppAvailable) {
      setError(errors.PAGE_NOT_FOUND);
    }
  }, [isWorkflowAppAvailable]);

  const renderMiniapp = useMemo(() => {
    if (error) {
      return <Error error={createError(error, errorHeading(activeAppInstance?.id))} setError={setError} />;
    }

    if (!(selectedMerchantId && merchantsData)) {
      return <MiniAppLoading />;
    }

    activeAppInstance.appConfig = {
      ...activeAppInstance.appConfig,
      merchantId: selectedMerchantId,
      context: 'merchant',
      isIBUser: isIBUser(),
      dxFormsPaths: {
        business: `${miniapps.DX_FORM.route.business}`,
        stores: `${miniapps.DX_FORM.route.store}`,
        details: `${miniapps.DX_FORM.route.details}`
      }
    };

    // Notes: passing "shell" attribute as app parameter as requested by PEGA
    activeAppInstance.params = {
      ...activeAppInstance.params,
      shell: 'Hive'
    };

    return (
      <div key={`instance-${selectedMerchantId}`}>
        <MiniAppInstance appInstance={activeAppInstance} loadingSlot={MiniAppLoading} errorSlot={MiniAppError} />
      </div>
    );
  }, [error, selectedMerchantId, merchantsData]);

  return <AppViewport id="appViewPort">{renderMiniapp}</AppViewport>;
}

export default WorkflowManagement;
