import React, { useContext, useEffect, useMemo, useState } from 'react';
import { H2, Link, useMediaQuery, Button, ButtonIcon, LogOutIcon, HamburgerIcon, CloseIcon, HelpIcon, ProfileIcon } from '@nab/nui-react';
import { EmbeddedMiniApp, ReactSpaContext } from '@nab/x-spa-react';
import { intersection } from 'lodash';
import { HeaderWrapper, LogoImage, LinkList, ScreenReaderSpan } from './Header.styles';
import MobileMenu from './MobileMenu/MobileMenu';
import PollinateMenu from './PollinateMenu/PollinateMenu';
import { findApp } from '../../utils/appContext';
import { getFeatureFlagsFromMenu } from '../../utils/nav-manager/menu';
import { getBearerToken, getPreferredLoginMethod, isIBUser, logout, navigateToLogin } from '../../utils/session';
import constants, { miniapps } from '../../utils/constants';
import nabLogo from '../../images/nab_logo.svg';
import { ShellContext } from '../Spa/GlobalSpa/GlobalSpa';

interface HeaderProps {
  setError?: (value: string) => void;
  currentRoute?: string;
  showPollinateMenu?: boolean;
}

export function Header({ setError, currentRoute, showPollinateMenu }: HeaderProps) {
  const { actions, activeAppInstance, context } = useContext(ReactSpaContext);
  const { featureFlags: allFeatureFlags } = useContext(ShellContext);
  const [hasUserInfoError, setHasUserInfoError] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [renderUserInfo, setRenderUserInfo] = useState(false);
  const bearerToken = getBearerToken();
  const isMediumScreen = !useMediaQuery({ maxWidth: 'md' });
  const isLargeScreen = useMediaQuery({ minWidth: 'lg' });
  const [mobileMenuErrorFlag, setMobileMenuErrorFlag] = useState(false);

  const [filteredMenu, setFilteredMenu] = useState([]);
  const { selectedMerchantId } = useContext(ShellContext);

  // TODO: remove when DAF has been updated
  // localStorage.setItem(constants.BEARER_TOKEN, 'bearerToken');

  useEffect(() => {
    // Only do flag check if there is item in the menu
    if (context?.menu?.length > 0) {
      let updatedMenu = context?.menu.slice(0);
      const featureFlags = getFeatureFlagsFromMenu(updatedMenu);

      // Flag check
      const isFeatureFlagEnabled = menuFlags => {
        if (menuFlags) {
          const flagKeys = menuFlags.split(',');
          const includedFlags = intersection(featureFlags, flagKeys);
          let visible = false;

          if (flagKeys.length > 0 && includedFlags.length > 0) {
            // Note: if there are multiple flags (separated by commas), visibility is only needed if one item is turned on
            includedFlags.forEach(flag => {
              if (visible) return;
              visible = allFeatureFlags[flag];
            });
          }
          return visible;
        }
        return false;
      };

      const isDisabledForIBUser = shellConfig => {
        return shellConfig?.excludeIBUsers === true;
      };

      updatedMenu = updatedMenu.flatMap(menuItem => {
        if (menuItem.children) {
          menuItem.children = menuItem.children.flatMap(submenuItem => {
            if (submenuItem.shellConfig) {
              const isEnabledFeature = isFeatureFlagEnabled(submenuItem.shellConfig.featureFlags);
              if (isEnabledFeature) {
                return isIBUser() && isDisabledForIBUser(submenuItem.shellConfig) ? [] : [submenuItem];
              } else {
                return [];
              }
            } else {
              return [submenuItem];
            }
          });
        }

        if (menuItem.shellConfig) {
          const isEnabledFeature = isFeatureFlagEnabled(menuItem.shellConfig.featureFlags);
          if (isEnabledFeature) {
            return isIBUser() && isDisabledForIBUser(menuItem.shellConfig) ? [] : [menuItem];
          } else {
            return [];
          }
        }

        return [menuItem];
      });

      setFilteredMenu(updatedMenu);
    }
  }, [context?.menu]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      actions.dispatchEvent(constants.MOBILE_MENU_CLOSE);
      setIsMobileMenuOpen(false);
    }
  }, [selectedMerchantId]);

  useEffect(() => {
    actions.dispatchEvent(isMobileMenuOpen ? constants.MOBILE_MENU_OPEN : constants.MOBILE_MENU_CLOSE);
  }, [isMobileMenuOpen]);

  /* Setting a delay to 5s for the embedded user info miniapp username. This is so akamaighost does not get
   * triggered from all the token calls at once during login.
   */
  useEffect(() => {
    let timer = setTimeout(() => {
      setRenderUserInfo(true);
    }, constants.USER_INFO_DELAY);

    return () => {
      clearTimeout(timer);
    };
  }, [])

  const logoutClicked = async () => {
    await logout();
    await actions.dispatchEvent(constants.POLLINATE_MINIAPP_LOGOUT);
    actions.removeEventListener(constants.RENDER_ERROR);
    navigateToLogin();
  };

  /* istanbul ignore next */
  function skipFocus(e: Event) {
    document.querySelector('h1')?.focus();
    if (!activeAppInstance) {
      return;
    }

    // to select the latest miniapp element for multiple miniapp elements
    const miniappElements = document.querySelectorAll(activeAppInstance.app.id);
    const miniappElement = miniappElements[miniappElements.length > 1 ? miniappElements.length - 1 : 0];
    // for pollinate miniapp: to fix
    if (activeAppInstance.app.id === miniapps.POLLINATE.name) {
      e.preventDefault();
      miniappElement?.shadowRoot
        ?.querySelector('iframe')
        .contentWindow.document.getElementsByTagName('h1')[0]
        ?.focus();
    }
    miniappElement?.shadowRoot?.querySelector('h1')?.focus();
  }

  function toggleMobileMenuErrorFlag() {
    setMobileMenuErrorFlag(true);
  }

  useEffect(() => {
    if (isMobileMenuOpen && !isLargeScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  });

  const userInfoAppConfig = useMemo(() => {
    return context ? findApp(context, miniapps.USER_INFO.name) : null;
  }, [context]);

  const userInfoMiniApp = useMemo(() => {
    const loginTenant = getPreferredLoginMethod() === constants.LOGIN_METHOD_IB ? 'ib' : 'nabc';
    return userInfoAppConfig ? (
      <EmbeddedMiniApp
        appMetaData={userInfoAppConfig}
        appConfig={{
          view: 'LINK',
          tenant: loginTenant
        }}
        onError={() => setHasUserInfoError(true)}
      />
    ) : (
      <></>
    );
  }, [userInfoAppConfig]);

  useEffect(() => {
    actions.addEventListener(constants.MOBILE_MENU_ERROR, toggleMobileMenuErrorFlag);

    return () => {
      actions.removeEventListener(constants.MOBILE_MENU_ERROR);
    }
  }, [])

  return (
    <>
      <HeaderWrapper className={isMobileMenuOpen && !isLargeScreen ? 'unscrollable' : ''}>
        {!mobileMenuErrorFlag && !isLargeScreen && (
          <>
            <ButtonIcon
              label={isMobileMenuOpen ? 'hamburgerMenuButtonOpen' : 'hamburgerMenuButtonClose'}
              icon={isMobileMenuOpen ? CloseIcon : HamburgerIcon}
              id="hamburgerMenuButton"
              isOnDarkBg
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            />
            <MobileMenu
              menu={filteredMenu}
              isMenuOpen={isMobileMenuOpen}
              setIsMenuOpen={setIsMobileMenuOpen}
              logoutClicked={logoutClicked}
              setError={setError}
              currentRoute={currentRoute}
              showPollinateMenu={showPollinateMenu}
            />
          </>
        )}
        <LogoImage src={nabLogo} alt="NAB Logo" />
        <H2 id="nabHiveLabel" isOnDarkBg variant="impact">
          NAB HIVE
        </H2>
        {(!isMobileMenuOpen || isLargeScreen) && (
          <Link href={window.location.pathname + '#bodyContent'} onClick={(e: Event) => skipFocus(e)} isOnDarkBg>
            Skip to main content
          </Link>
        )}
        {isLargeScreen && (
          <nav aria-label="header">
            <LinkList>
              <li>
                <Link
                  id="helpLink"
                  isOnDarkBg
                  href="https://www.nab.com.au/business/payments-and-merchants/merchant-support-centre"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={HelpIcon}
                >
                  Help
                  <ScreenReaderSpan>, opens in new window</ScreenReaderSpan>
                </Link>
              </li>
              {bearerToken && (
                <li>
                  {hasUserInfoError || !renderUserInfo ? (
                    <Link
                      data-testid="myDetailsLink"
                      href={miniapps.USER_INFO.route}
                      isOnDarkBg
                      icon={ProfileIcon}
                      onClick={async (e: Event) => {
                        e.preventDefault();
                        await actions.navigate(miniapps.USER_INFO.route);
                        setError?.(null);
                      }}
                    >
                      My Details
                    </Link>
                  ) : (
                    userInfoMiniApp
                  )}
                </li>
              )}
            </LinkList>
          </nav>
        )}
        {bearerToken &&
          (isMediumScreen ? (
            <Button id="logoutButton" variant="primary" label="Logout" data-testid="LogoutButton" onClick={() => logoutClicked()} />
          ) : (
            <ButtonIcon
              id="logoutButtonMobile"
              label="Logout"
              icon={LogOutIcon}
              isOnDarkBg
              data-testid="LogoutButtonMobile"
              onClick={() => {
                setIsMobileMenuOpen(false);
                logoutClicked();
              }}
            />
          ))}
      </HeaderWrapper>
      {showPollinateMenu && filteredMenu && isLargeScreen && <PollinateMenu menu={filteredMenu} currentRoute={currentRoute} setError={setError} />}
    </>
  );
}

export default Header;
