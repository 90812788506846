import { uniq, flatten, flatMapDeep } from 'lodash';
import { NavManagerMenuItem } from '../../components/Header/PollinateMenu/PollinateMenu';

export const getFeatureFlagsFromMenu = (menuItems: NavManagerMenuItem[]) => {
  let flags = [];
  const iterateMenuItem = menuItem => {
    if (menuItem.shellConfig && menuItem.shellConfig.featureFlags) {
      flags.push(menuItem.shellConfig.featureFlags.split(','));
      flags = uniq(flatten(flags));
    }
    if (!menuItem.children || !menuItem.children.length) {
      return menuItem;
    }
    return [menuItem, flatMapDeep(menuItem.children, iterateMenuItem)];
  };
  flatMapDeep(menuItems, iterateMenuItem);
  return flags;
};
